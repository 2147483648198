import axios from "axios";

export const createRefund = async (id: string | undefined) => {
    if (!id) throw new Error("Payment Session ID is undefined; unable to process refund.");

    try {
        // Check if the shipment was paid with card or invoiced
        await axios.put(`${import.meta.env.VITE_API_URL}/Stripe/${id.startsWith("ii") ? "invoice-item" : "refund"}/${id}`);
    } catch (e) {
        throw new Error(e);
    }
};
