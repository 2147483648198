import { createContext, useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuthStore } from "@/core/store";
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import Header from "./Header";
import NavBar from "./NavigationMenu";
import { useUserStore } from "@/core/UserStore";
import { auth } from "@/core/firebase";
import { useAppContext } from "@/onboarding/context";
import { useMount } from "react-use";

export default function MainLayout() {
    const user = useUserStore((state) => state.user);
    const isLoggedIn = useUserStore((state) => state.isLoggedIn);
    // const loading = useUserStore((state) => state.loading);

    const navigate = useNavigate();
    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/login", { replace: true });
        }
    }, [user]);

    const {
        setState,
        state: { tourActive }
    } = useAppContext();

    useMount(() => {
        if (tourActive) {
            setState({ run: true, stepIndex: 3 });
        }
    });

    return (
        <>
            <div className="flex h-screen items-center justify-center p-4 text-center lg:hidden">
                SnapShip isn't fully optimized for smaller devices yet. For the best experience, please use a desktop or larger screen.
            </div>
            <div className="hidden h-full w-full grid-rows-[76px_1fr] overflow-hidden lg:grid">
                <Header />
                <div className="grid grid-cols-[280px_1fr] overflow-hidden">
                    <NavBar />
                    <div className="overflow-y-auto p-6" id="third-step">
                        {/* <Banner>Test</Banner> */}
                        <BannerWrapper />

                        <Outlet />
                    </div>
                </div>
                <Toaster />
                <Sonner />
            </div>
        </>
    );
}
import { replace } from "lodash";
import { run } from "node:test";
import { Banner, BannerWrapper } from "../composite/Banner";
