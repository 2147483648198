import RadioButtons from "@/components/composite/Inputs/RadioButtons";
import Select from "@/components/composite/Inputs/Select";
import { useFormToggle } from "@/context/FormToggleContext";
import { useFormStore } from "@/core/ShipmentFormStore";
import { useState, useEffect, memo } from "react";
import { useFormContext } from "react-hook-form";

const Units = [
    {
        name: "imperial",
        label: "Imperial (lb, in)"
    },
    {
        name: "metric",
        label: "Metric (kg, cm)"
    }
];

const Signature = [
    {
        name: "none",
        label: "No signature"
    },
    {
        name: "regular",
        label: "Regular Signature"
    },
    {
        name: "adult",
        label: "Adult Signature"
    }
];

export default function ShipmentOptions() {
    const { getValues, setValue } = useFormContext();

    return (
        <>
            <RadioButtons options={Units} name="units" defaultValue={getValues("units") || "imperial"} />
            {/* <RadioButtons options={Signature} name="signature" defaultValue={getValues("signature") || "none"} /> */}
            <Select SelectItemList={Signature} name="signature" label="Signature Options" defaultValue={getValues("signature") || "none"} />
        </>
    );
}
