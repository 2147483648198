import { get, useFormContext } from "react-hook-form";

import { Label } from "@/components/ui/label";

import { Textarea, TextareaProps } from "@/components/ui/textarea";

interface TextboxProps extends TextareaProps {
    label?: string;
    name: string;
}

export default function Textbox({ label, name, ...props }: TextboxProps) {
    const {
        register,
        formState: { errors }
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    return (
        <div className="grid gap-2">
            {label && <Label htmlFor={name}>{label}</Label>}
            <Textarea id={name} {...register(name)} {...props} />
            {errorMessage !== undefined ? <p className="text-xs text-red-500">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
        </div>
    );
}
