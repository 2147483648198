import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import print from "print-js";

import { Button } from "@/components/ui/button";
import { useState } from "react";
import { CircleAlert, Info } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { replace } from "lodash";
import { PackageDimensions, ServiceDetails, Shipment } from "@shared/ShipmentTypes";
import printJS from "print-js";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";

type LabelsDetails = {
    trackingNumber: string | undefined;
    label: string | undefined;
    form?: string | undefined;
    status?: string | undefined;
    carrier: string | undefined;
    deliveryFormat: string | undefined;
    packageType: "Package" | "Letter" | "Tube" | undefined;
    packages: PackageDimensions;
};

interface Details {
    shipment: Shipment;
}

export default function LabelsDetails({ shipment }: Details) {
    const {
        trackingNumber,
        shipmentDetails: { deliveryFormat, shipmentType, packages },
        serviceDetails,
        formURL,
        labelURL,
        status
    } = shipment;

    const isUPSThermal = shipment.serviceDetails?.carrier === "UPS" && shipment.shipmentDetails.printerType === "thermal";
    shipment.serviceDetails?.carrier === "UPS" && shipment.shipmentDetails.printerType === "thermal";

    const navigate = useNavigate();

    return (
        <Card className="h-fit border-2">
            <CardHeader>
                <CardTitle>Print Documentation </CardTitle>
                <CardDescription className="my-2 font-bold">
                    Package Tracking Number: <span className="font-normal">{trackingNumber}</span>
                </CardDescription>
                {isUPSThermal && <span className="!mt-5 text-xs text-gray-500"> When printing the thermal label, remove margins, headers and footers to get a larger label if desired.</span>}
            </CardHeader>
            <CardContent className="space-y-4">
                <LabelOptions label={labelURL} status={status} isUPSThermal={isUPSThermal} packages={shipment.shipmentDetails.packages} />
                {formURL && <CustomsOptions form={formURL} status={status} />}

                {packages.length > 1 &&
                    packages.map((packageitem, index) => {
                        if (packageitem.shipmentDetails) {
                            return (
                                <LabelOptions
                                    key={index}
                                    label={packageitem.shipmentDetails.labelURL}
                                    status={status}
                                    index={index}
                                    trackingNumber={packageitem.shipmentDetails.trackingNumber}
                                    isUPSThermal={isUPSThermal}
                                />
                            );
                        }
                    })}
            </CardContent>
            <CardFooter>
                <div className="col-span-2 text-xs text-gray-500">
                    <span>
                        You must use{" "}
                        {deliveryFormat === "package" ? (
                            <span>
                                your <strong>own packaging</strong> for this shipment.
                            </span>
                        ) : (
                            <span>
                                <strong>
                                    {serviceDetails?.carrier} {shipmentType === "Letter" && deliveryFormat === "pak" ? "Pak" : shipmentType === "Package" ? "Packaging" : shipmentType}
                                </strong>{" "}
                                for this shipment. To request carrier packaging, visit{" "}
                                <span
                                    className="cursor-pointer text-blue-500 underline"
                                    onClick={() => {
                                        navigate("/settings/carriers", { replace: true });
                                    }}>
                                    your carriers settings.
                                </span>
                            </span>
                        )}{" "}
                    </span>
                </div>
            </CardFooter>
        </Card>
    );
}

interface LabelOptions {
    label?: string;
    status?: string;
    index?: number | undefined;
    trackingNumber?: string;
    isUPSThermal?: boolean;
    packages?: Shipment["shipmentDetails"]["packages"];
}

const LabelOptions = ({ label, status, index, trackingNumber, isUPSThermal, packages }: LabelOptions) => {
    console.log(isUPSThermal);
    const download = () => {
        // Create a link element, hide it, direct it towards the blob, and then 'click' it programmatically
        if (label) {
            const downloadLink = document.createElement("a");
            downloadLink.href = label;
            downloadLink.download;
            downloadLink.target = "_blank";
            downloadLink.click();
        }
    };

    const handlePrintPdf = async () => {
        if (!!isUPSThermal && packages && index === undefined) {
            let printArray: string[] = [];

            await Promise.all(
                packages.map(async (box) => {
                    const label = box.shipmentDetails?.labelURL as string;
                    const response = await fetch(label);
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob); // Create an object URL for the blob
                    printArray.push(url); // Push the URL to the array
                })
            );
            printJS({
                printable: printArray,
                type: "image",
                imageStyle: "width:100%;display:block;margin-bottom:50px;",
                header: "",
                style: `
                    @media print {
                        img { 
                        page-break-after: always; 
                        display: block; 
                        width: 100%; 
                        margin-bottom: 50px;
                        }
                        img:last-child {
                        page-break-after: avoid;
                        }
                        @page {
                        margin: 0;  /* To set the print margins to 0 */
                        }
                        body {
                        margin: 0;  /* To set the body margins to 0 */
                        }
                    }
                `
            });

            return;
        }

        if (!!isUPSThermal) {
            const response = await fetch(label as string);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob); // Create an object URL for the blob

            printJS({
                printable: url,
                type: "image",
                header: "",
                imageStyle: "width:100%;page-break-after:always;display:block;margin-bottom:50px;",
                style: `
                    @media print {
                        img { 
                            page-break-after: always; 
                            display: block; 
                            width: 100%; 
                            margin-bottom: 50px;
                        }
                        img:last-child {
                            page-break-after: avoid;
                        }
                    }
                `
            });

            return;
        }

        // console.log(label);
        if (label) {
            const response = await fetch(label);
            const blob = await response.blob();
            const base64 = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });

            const rawBase64 = (base64 as string).replace("data:application/pdf;base64,", "");
            print({ base64: true, type: "pdf", printable: rawBase64 });

            // print(label);
        }
    };

    return (
        <div className="flex h-fit flex-col gap-2">
            <div>
                <p className="h-fit font-bold">{index !== undefined ? `Labels ${index + 1}` : "Master Label"}</p>
                {index !== undefined && <p className="my-2 text-sm">Tracking Number: {trackingNumber}</p>}
            </div>

            <div className={isUPSThermal !== true ? "grid grid-cols-2 gap-2" : "grid grid-cols-1 gap-2"}>
                {/*
                    button should be disabled if thermal is true and it is the master label 
                    it's the master label when index is undefined
                */}
                {isUPSThermal !== true && (
                    <Button disabled={status === "Cancelled"} variant="secondary" className="w-full" onClick={download}>
                        Download Label
                    </Button>
                )}
                <Button disabled={status === "Cancelled"} variant="secondary" className="w-full" onClick={handlePrintPdf}>
                    Print Label
                </Button>
            </div>
        </div>
    );
};

const CustomsOptions = ({ form, status }) => {
    const download = () => {
        const downloadLink = document.createElement("a");

        downloadLink.href = form;
        downloadLink.download;
        downloadLink.target = "_blank";
        downloadLink.click();
    };
    const handlePrintPdf = async () => {
        console.log(form);
        // print(form);
        const response = await fetch(form);
        const blob = await response.blob();
        const base64 = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        // console.log(base64);
        const rawBase64 = (base64 as string).replace("data:application/pdf;base64,", "");
        print({ base64: true, type: "pdf", printable: rawBase64 });
        // print(label);
    };
    return (
        <div className="flex h-fit flex-col gap-2">
            <p className="h-fit font-bold">Customs Invoice</p>
            <div className="grid grid-cols-2 gap-2">
                <Button disabled={status == "Cancelled"} variant="secondary" className="w-full" onClick={download}>
                    Download Form
                </Button>
                <Button disabled={status == "Cancelled"} variant="secondary" className="w-full" onClick={handlePrintPdf}>
                    Print Form
                </Button>
            </div>
        </div>
    );
};

const PrintTooltip = () => {
    return (
        <TooltipProvider delayDuration={50}>
            <Tooltip>
                <TooltipTrigger type="button">
                    <Info className="ml-2 h-4" />
                </TooltipTrigger>
                <TooltipContent className="text-sm normal-case" align="start">
                    When printing the thermal label, remove margins, headers and footers to get a larger label if desired.
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};
