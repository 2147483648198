import { db } from "@/core/firebase";
import { collection, getDocs, limit, orderBy, query, where, doc, getDoc, onSnapshot } from "firebase/firestore";

import { User } from "firebase/auth";
import { Shipment } from "@shared/ShipmentTypes";

/**
 * Returns a shipment object promise given non-null shipment ID.
 *
 * @param {User | null} user - The ID of the shipment to retrieve.
 * @throws {Error} If the shipment ID is null or if the shipment is not found.
 * @returns {Promise<Shipment>} A promise that resolves to the shipment data.
 */
export const getLatestShipment = async (user: User | null) => {
    if (user === null) {
        throw new Error("User is null");
    }

    const shipmentRef = collection(db, "shipments");
    const queryFirestore = query(shipmentRef, where("uid", "==", user?.email), orderBy("date", "desc"), limit(1));
    const shipments = await getDocs(queryFirestore);
    if (!shipments.empty) {
        console.log(shipments.docs[0].data());
        return shipments.docs[0].data();
    } else {
        console.log("No shipments found");
        return null;
    }
};

/**
 * Returns a shipment object promise given the shipment's ID.
 *
 * @param {string} shipmentId - The ID of the shipment to retrieve.
 * @throws {Error} If the shipment ID is null or if the shipment is not found.
 * @returns {Promise<Shipment>} A promise that resolves to the shipment data.
 */
export const getShipmentByID = async (shipmentId: string) => {
    const shipmentRef = doc(db, "shipments", shipmentId);
    const shipment = await getDoc(shipmentRef);

    if (!shipment.exists()) {
        throw new Error("Shipment not found");
    }

    return shipment.data() as Shipment;
};
