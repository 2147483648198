import { AxiosError } from "axios";
import { ReactNode } from "react";

export const handlePurolatorPickupError = (e: AxiosError<any, any>): string[] => {
    const messages: string[] = [];
    // errorCodes needed because duplicate error is sent back (e.g Postal Code is invalid)
    const errorCodes = new Set();
    for (const error of e?.response?.data?.ResponseInformation?.Errors?.Error || []) {
        if (!errorCodes.has(error?.Code)) {
            errorCodes.add(error.Code);
            messages.push(error?.Description);
        }
    }

    return messages;
};

export const handleFedExPickupError = (e: AxiosError<any, any>): string[] => {
    const messages: string[] = [];

    for (const error of e?.response?.data?.errors || []) {
        messages.push(error?.message);
    }

    return messages;
};

export const handleUPSPickupError = (e: AxiosError<any, any>): string[] => {
    const messages: string[] = [];

    for (const error of e?.response?.data?.response?.errors || []) {
        messages.push(error?.message);
    }

    return messages;
};

export const handleGLSPickupError = (e: AxiosError<any, any>): string[] => {
    const messages: string[] = [];
    const errors = e?.response?.data;

    // Iterate through errorTypes and access the error messages in them
    for (const errorType in errors) {
        for (const error of errors[errorType]) {
            messages.push(error);
        }
    }

    return messages;
};

export const handlePickupErrorToastDescription = (e: AxiosError, carrier: "FexEx" | "Purolator" | "UPS" | "GLS" | string | undefined) => {
    let description: ReactNode = "Please try again";

    let messages: string[] = [];

    if (carrier === "Purolator") {
        messages = handlePurolatorPickupError(e);
    } else if (carrier === "FedEx") {
        messages = handleFedExPickupError(e);
    } else if (carrier === "UPS") {
        messages = handleUPSPickupError(e);
    } else if (carrier === "GLS") {
        messages = handleGLSPickupError(e);
    }

    if (messages.length > 0) {
        description = (
            <>
                {messages.map((message: string, index: number) => {
                    return (
                        <>
                            {message}
                            {index !== message.length - 1 ? <br /> : <></>}
                        </>
                    );
                })}
            </>
        );
    }

    return description;
};
