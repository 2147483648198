import { Truck, Tag, PackageCheck, CircleCheckBig, CircleX, Package, Route, TriangleAlert, MailWarning } from "lucide-react";

export type Status = "Label Created" | "In-Transit" | "Delay / Warning" | "Cancelled" | "Delivered" | "Refer to tracking link";

const mapStatus = (statusCode: string, carrier: string, previousStatus?: Status) => {
    let status: Status = "Refer to tracking link";
    if (carrier == "ups") {
        switch (statusCode) {
            case "003":
                status = "Label Created";
                break;
            case "013":
            case "048":
            case "055":
            case "029":
            case "030":
            case "027":
            case "033":
            case "019":
                status = "Delay / Warning";
                break;
            case "014":
            case "005":
            case "012":
            case "057":
            case "044":
            case "025":
            case "160":
            case "021":
            case "010":
            case "040":
            case "017":
            case "038":
                status = "In-Transit";
                break;
            case "011":
                status = "Delivered";
                break;
            case "007":
                status = "Cancelled";
                break;
            default:
                break;
        }
    } else if (carrier == "fedex") {
        switch (statusCode) {
            case "DL":
                status = "Delivered";
                break;
            case "PU":
            case "PX":
            case "IT":
            case "IX":
            case "LO":
            case "AP":
            case "DO":
                status = "In-Transit";
                break;
            case "DE":
            case "DD":
            case "SE":
            case "DY":
            case "CC":
            case "CD":
            case "CP":
                status = "Delay / Warning";
                break;
            case "CA":
                status = "Cancelled";
                break;

            case "OC":
            case "NEWS":
                status = "Label Created";
                break;
            default:
                break;
        }
    } else if (carrier == "purolator") {
        if (statusCode.includes("Shipment delivered")) {
            status = "Delivered";
        } else if (
            statusCode.includes("Undeliverable") ||
            statusCode.includes("Available for pickup for 5 business days from arrival date at the counter.") ||
            statusCode.includes("Attempted Delivery - Customer Closed") ||
            statusCode.includes("Attempted delivery - receiver unavailable")
        ) {
            status = "Delay / Warning";
        } else if (
            statusCode.includes("Departed sort facility") ||
            statusCode.includes("On vehicle for delivery") ||
            statusCode.includes("Arrived at sort facility") ||
            statusCode.includes("Picked up by Purolator") ||
            statusCode.includes("Arrived at sort facility")
        ) {
            status = "In-Transit";
        } else if (statusCode.includes("Shipment created") || statusCode.includes("Shipper created a label")) {
            status = "Label Created";
        } else if (statusCode.includes("Label information electronically submitted")) {
            status = previousStatus!;
        }
    } else if (carrier == "gls") {
        // TODO: Figure out status mapping for GLS
        switch (statusCode) {
            case "CR":
                status = "Label Created";
                break;
            case "OD": // double check
                status = "Delay / Warning";
                break;
            case "PU":
            case "IT":
                status = "In-Transit";
                break;
            case "DL":
                status = "Delivered";
                break;
            default:
                status = "Refer to tracking link";
                break;
        }
    }

    return status;
};

const statusStyleMapping: Record<Status, string> = {
    "Label Created": "text-gray-500",
    "In-Transit": "text-warning-500",
    "Delay / Warning": "text-warning-500",
    Cancelled: "text-error-600",
    Delivered: "text-success-600",
    "Refer to tracking link": "text-warning-600"
};

const statusIconMapping: Record<Status, React.ReactElement> = {
    "Label Created": <Tag className="mr-2 h-4 w-4" />,
    "In-Transit": <Truck className="mr-2 h-4 w-4" />,
    "Delay / Warning": <TriangleAlert className="mr-2 h-4 w-4" />,
    Cancelled: <CircleX className="mr-2 h-4 w-4" />,
    Delivered: <CircleCheckBig className="mr-2 h-4 w-4" />,
    "Refer to tracking link": <MailWarning className="mr-2 h-4 w-4" />
};

const statusIconMappingBig: Record<Status, React.ReactElement> = {
    "Label Created": <Tag className="mr-2 h-10 w-10" />,
    "In-Transit": <Truck className="mr-2 h-10 w-10" />,
    "Delay / Warning": <TriangleAlert className="mr-2 h-10 w-10" />,
    Cancelled: <CircleX className="mr-2 h-10 w-10" />,
    Delivered: <CircleCheckBig className="mr-2 h-10 w-10" />,
    "Refer to tracking link": <MailWarning className="mr-2 h-10 w-10" />
};

export { statusStyleMapping, statusIconMapping, mapStatus, statusIconMappingBig };
