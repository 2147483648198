import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useUserStore } from "@/core/UserStore";
import { doc, updateDoc, getDocs, query, collection } from "firebase/firestore";
import { db } from "@/core/firebase";

import { ChevronRight, Megaphone, MoveRight, X } from "lucide-react";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

export interface BannerProps {
    id: string;
    text: string;
    component?: string | ReactNode;
    isActive: boolean;
}

export function Banner({ id, text, component, isActive }: BannerProps) {
    const userData = useUserStore((state) => state.userData);
    const editData = useUserStore((state) => state.editUserData);

    const [open, setOpen] = useState(!userData?.settings?.closeBanners?.[id] && isActive);

    const handleClick = async () => {
        setOpen(false);
        const updatedSettings = {
            settings: {
                ...userData?.settings,
                closeBanners: {
                    ...userData?.settings?.closeBanners,
                    [id]: true
                }
            }
        };
        editData(updatedSettings);

        const userRef = doc(db, "users", userData?.email as string);
        await updateDoc(userRef, updatedSettings);
    };

    return open ? (
        <Alert className="bg-brand-900 mb-4 grid items-center align-middle text-white" key={id}>
            <Megaphone className="h-4 w-4 text-white" color="white" />
            {/* <MoveRight className="h-4 w-4" color /> */}
            <AlertTitle className="flex w-full">
                Heads up! <X className="ml-auto h-4 w-4" onClick={handleClick} />
            </AlertTitle>
            <AlertDescription className="h-full">{component ? component : text}</AlertDescription>
        </Alert>
    ) : (
        <></>
    );
}

const GLSBanner: BannerProps = {
    id: "GLS",
    text: "Exciting news! GLS, a Canadian carrier, has joined our platform! They offer reliable, affordable parcel and envelope shipping across Canada—think Purolator, but cheaper. Enable GLS on your carriers page to unlock these amazing rates!",
    component: (
        <p>
            Exciting news! GLS, a Canadian carrier, has joined our platform! They offer reliable, affordable parcel and envelope shipping across Canada—<b>think Purolator, but cheaper. Enable GLS</b>{" "}
            on your{" "}
            <Link to="/settings/carriers" className="underline">
                carriers page
            </Link>{" "}
            to unlock these amazing rates!
        </p>
    ),
    isActive: true
};

export function BannerWrapper() {
    const [banners, setBanners] = useState<BannerProps[] | null>();

    const queryForBanners = async () => {
        try {
            const q = query(collection(db, "banners"));

            const querySnapshot = await getDocs(q);

            const bannerList = querySnapshot.docs.map((doc) => {
                const data = doc.data() as BannerProps;
                return data;
            });

            return bannerList;
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    useEffect(() => {
        queryForBanners().then((bannerList) => {
            if (bannerList) {
                const updatedBanners = [...bannerList, GLSBanner];
                setBanners(updatedBanners);
            } else {
                setBanners([GLSBanner]);
            }
        });
    }, []);

    return (
        <>
            {banners &&
                banners.map((banner) => {
                    return <Banner text={banner.text} component={banner.component} id={banner.id} isActive={banner.isActive} key={banner.id} />;
                })}
        </>
    );
}
