import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Contact() {
    const navigate = useNavigate();

    useEffect(() => {
        window.open("https://snapship.co/contact-us/", "_blank");
        navigate("/", { replace: true });
    });
    return null;
}
