import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { MoreHorizontal, ChevronsUpDown, Copy } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { useNavigate } from "react-router-dom";
import { statusIconMapping, statusStyleMapping } from "@/lib/statusMapping";
import { trackShipment, voidShipment } from "@/api/shipments/updateShipment";
import { toast } from "@/components/ui/use-toast";
import { cancelPickup, removePickupFromShipment, updatePickupStatus } from "@/api/pickups/updatePickups";
import { createRefund } from "@/api/payments/createRefund";
import { getPickupByShipmentID } from "@/api/pickups/getPickup";
import { getShipmentByID } from "@/api/shipments/getShipment";
import { ShipmentsTableHeader, Status } from "@shared/ShipmentTypes";
import React, { useState } from "react";
import dayjs from "dayjs";

/**
 * Renders a cell with copyable content in a table column.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.cell - The cell object containing the value to be displayed and copied.
 *
 * @returns {JSX.Element} A JSX element representing the copyable column cell.
 */
const copyableColumn = ({ cell }) => {
    const [copy, setCopy] = useState(false);

    return (
        <div className="flex items-center" onMouseEnter={() => setCopy(true)} onMouseLeave={() => setCopy(false)}>
            <span>{cell.getValue()}</span>
            <Copy
                className={`ml-2 h-3 w-3 text-gray-500 ${copy ? "hover:text-primary" : "invisible"}`}
                onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(cell.getValue() as string);
                    toast({
                        description: `${cell.getValue()} copied to clipboard.`
                    });
                }}
            />
        </div>
    );
};

const handleActionItemClick = (e: React.MouseEvent, callback: () => void) => {
    e.stopPropagation();
    callback();
};

export const columns: ColumnDef<ShipmentsTableHeader>[] = [
    // * not needed rn, possibly useful for future
    // {
    //     id: "select",
    //     header: ({ table }) => (
    //         <Checkbox
    //             checked={table.getIsAllPageRowsSelected() ? true : table.getIsSomePageRowsSelected() ? "indeterminate" : false}
    //             onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //             aria-label="Select all"
    //         />
    //     ),
    //     cell: ({ row }) => <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />,
    //     enableSorting: false,
    //     enableHiding: false
    // },
    {
        accessorKey: "id",
        header: "ID",
        cell: ({ cell }) => copyableColumn({ cell })
    },
    {
        accessorKey: "carrier",
        header: ({ column }) => {
            return (
                <Button className="ml-[-1rem]" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                    Carrier
                    <ChevronsUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            return (
                <>
                    <Badge className="bg-gray-700">{row.original.carrier}</Badge>
                </>
            );
        }
    },
    {
        accessorKey: "customerName",
        header: ({ column }) => {
            return (
                <div className="ml-[-0.25rem]">
                    <Button variant="ghost" className="data-[state=open]:bg-accent -ml-3 h-8" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Name
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        cell: ({ row }) => {
            return <div>{row.original.customerName}</div>;
        }
    },
    {
        accessorKey: "date",
        header: ({ column }) => {
            return (
                <div className="ml-[-0.25rem]">
                    <Button variant="ghost" className="data-[state=open]:bg-accent -ml-3 h-8" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Date and Time
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        cell: ({ row }) => {
            return <div className="w-44">{dayjs(row.original.date).format("MMM D YYYY | hh:mm A")}</div>;
        },
        sortingFn: (rowA, rowB) => {
            const dateA = rowA.original.date ? new Date(rowA.original.date).getTime() : Number.MIN_SAFE_INTEGER;
            const dateB = rowB.original.date ? new Date(rowB.original.date).getTime() : Number.MIN_SAFE_INTEGER;

            return dateA - dateB;
        },
    },
    {
        accessorKey: "status",
        header: ({ column }) => {
            return (
                <div className="ml-[-1rem]">
                    <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Status
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        cell: ({ row }) => {
            return (
                <div className={"flex items-center " + statusStyleMapping[row.original.status]}>
                    {statusIconMapping[row.original.status]}
                    {row.original.status}
                </div>
            );
        }
    },

    {
        accessorKey: "trackingNumber",
        header: ({ column }) => {
            return (
                <Button
                    className="ml-[-1rem]"
                    variant="ghost"
                    onClick={(e) => {
                        column.toggleSorting(column.getIsSorted() === "asc");
                    }}>
                    Tracking #
                    <ChevronsUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ cell }) => copyableColumn({ cell })
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const navigate = useNavigate();
            const [resolved, setResolved] = useState(row.original.status === "Cancelled");

            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Actions</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={(e) => handleActionItemClick(e, () => navigate(`/shipments/id/${row.original.id}`))}>View details</DropdownMenuItem>
                        <DropdownMenuItem onClick={(e) => handleActionItemClick(e, () => trackShipment(row.getValue("carrier"), row.getValue("trackingNumber")))}>Track</DropdownMenuItem>
                        <DropdownMenuItem
                            disabled={resolved}
                            onClick={(e) =>
                                handleActionItemClick(e, async () => {
                                    try {
                                        const shipment = await getShipmentByID(row.original.id);
                                        if (shipment.pickupDetails.pickupType === "pickup" && shipment.pickupDetails.id !== null) {
                                            const pickupData = await getPickupByShipmentID(shipment.id);
                                            await cancelPickup(pickupData);
                                            await updatePickupStatus(pickupData.id, shipment.id, { status: "cancelled" });
                                            await removePickupFromShipment(shipment.id);
                                        }
                                        await voidShipment(shipment.serviceDetails?.carrier, shipment.trackingNumber, shipment.id);
                                        await createRefund(shipment.paymentSessionID);
                                        setResolved(true); // Disables the void dropdown menu item
                                        toast({ className: "bg-success-400", title: "Shipment voided successfully." });
                                    } catch (e) {
                                        toast({ variant: "destructive", title: "Failed to void shipment.", description: "Error: " + e.message });
                                    }
                                })
                            }>
                            Void
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={(e) =>
                                handleActionItemClick(e, async () => {
                                    try {
                                        const shipment = await getShipmentByID(row.original.id);
                                        if (!shipment.labelURL) throw new Error("Unable to find an associated label.");
                                        const downloadLink = document.createElement("a");
                                        downloadLink.href = shipment.labelURL;
                                        downloadLink.download;
                                        downloadLink.target = "_blank";
                                        downloadLink.click();
                                    } catch (e) {
                                        toast({ variant: "destructive", title: "Failed to download label.", description: "Error: " + e.message });
                                    }
                                })
                            }>
                            Download label
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        }
    }
];
