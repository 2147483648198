import axios from "axios";
import { arrayRemove, arrayUnion, doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "@/core/firebase";
import { Shipment } from "@shared/ShipmentTypes";
import { getPickupByID, getPickupByShipmentID } from "./getPickup";
import { createPickup } from "./createPickup";
import { PickupData } from "@shared/PickupTypes";
import { getShipmentByID } from "../shipments/getShipment";
import dayjs from "dayjs";

type CancelResponseType = "Pickup cancelled" | "Pickup not cancelled" | "Pickup already cancelled";

const cancelPickup = async (pickupData: PickupData): Promise<CancelResponseType> => {
    if (pickupData?.carrier === "UPS") {
        return await cancelUPSPickup(pickupData.data);
    }
    if (pickupData?.carrier === "Purolator") {
        return await cancelPurolatorPickup(pickupData.data);
    }
    if (pickupData?.carrier === "FedEx") {
        return await cancelFedExPickup(pickupData);
    }
    if (pickupData?.carrier === "GLS") {
        return await cancelGLSPickup(pickupData.data);
    } else {
        throw "Unable to determine carrier for pickup cancellation";
    }
};

const cancelFedExPickup = async (pickupData: PickupData): Promise<CancelResponseType> => {
    try {
        const payload = {
            PickupDate: dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("YYYY-MM-DD"),
            PickupCode: pickupData.data
            // PickupLocation: pickupData.data.location
        };

        const response = await axios.put(`${import.meta.env.VITE_API_URL}/FedEx/cancel-pickup/`, payload);

        if (!response.data.errors) {
            return "Pickup cancelled";
        }
    } catch (e) {
        console.error(e);
    }

    return "Pickup not cancelled";
};

const cancelGLSPickup = async (ID: string): Promise<CancelResponseType> => {
    try {
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/GLS/cancel-pickup/${ID}`);

        if (response.status === 200) {
            return "Pickup cancelled";
        } else {
            throw response.data;
        }
    } catch (e) {
        console.error(e);
        if (e.response.data.response.errors[0].code === "9510131") {
            throw "Pickup already cancelled";
        } else {
            throw "Pickup not cancelled";
        }
    }
};

const cancelUPSPickup = async (PRN: string): Promise<CancelResponseType> => {
    try {
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/UPS/cancel-pickup/${PRN}`);

        if (response.data.PickupCancelResponse.Response.ResponseStatus.Description === "Success") {
            return "Pickup cancelled";
        } else if (response.data.response.errors[0].code === "9510131") {
            return "Pickup already cancelled";
        }
    } catch (e) {
        console.error(e);
    }

    return "Pickup not cancelled";
};

const cancelPurolatorPickup = async (PRN: string): Promise<CancelResponseType> => {
    try {
        const response = await axios.put(`${import.meta.env.VITE_API_URL}/Purolator/cancel-pickup/${PRN}`);
        console.log(response.data);
        if (response.data.PickUpVoided as Boolean) {
            return "Pickup cancelled";
        } else if (response.data.ResponseInformation.Errors && response.data.ResponseInformation.Errors.Error[0].Code === "4100742") {
            return "Pickup already cancelled";
        }
    } catch (e) {
        console.error(e);
    }

    return "Pickup not cancelled";
};

const updatePickupStatus = async (id: string | undefined, shipmentID: string | undefined, properties?: object) => {
    if (id === undefined || shipmentID === undefined) {
        throw "ID is undefined";
    }

    await setDoc(doc(db, "pickups", id), { ...properties, shipmentID: arrayRemove(shipmentID) }, { merge: true });
};

const removePickupFromShipment = async (id: string | undefined) => {
    if (id === undefined) {
        throw "ID is undefined";
    }
    try {
        await setDoc(
            doc(db, "shipments", id),
            {
                pickupDetails: {
                    id: null
                }
            } as Shipment,
            { merge: true }
        );
    } catch (e) {
        throw e;
    }
};

const addPickupToShipment = async (pickupID: string | undefined, shipmentID: string | undefined) => {
    if (pickupID === undefined) {
        throw "ID is undefined";
    }
    if (shipmentID === undefined) {
        throw "ID is undefined";
    }
    try {
        await updateDoc(doc(db, "pickups", pickupID), {
            shipmentID: arrayUnion(shipmentID)
        });
        await updateDoc(doc(db, "shipments", shipmentID), {
            pickupDetails: {
                pickupType: "pickup",
                id: pickupID
            }
        });
    } catch (e) {
        console.error(e);
    }
};

export { cancelPickup, updatePickupStatus, removePickupFromShipment, addPickupToShipment };
