import { Card, CardContent, CardHeader } from "@/components/ui/card";
import AddressForm, { CustomerContext } from "@/components/composite/AddressForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useStepper } from "@/components/ui/stepper";
import { customerSchema } from "@/schema/ShipmentSchema";
import Form from "@/components/composite/Form";
import FormTitle from "@/components/composite/Headers/FormTitle";
import { useFormStore } from "@/core/ShipmentFormStore";
import Checkbox from "@/components/composite/Inputs/Checkbox";
import { Button } from "@/components/ui/button";
import { useFormSteps } from "@/context/FormStepContext";
import { useSaveAddressStore } from "@/core/SaveAddress";

// extend schema to check if its international
const customerSchemaUpdated = customerSchema.superRefine((data, ctx) => {
    if (data.address.countryCode !== "CA" && !data.email) {
        ctx.addIssue({
            code: "custom",
            message: "Please add an email as this is an international shipment",
            path: ["email"],
            fatal: true
        });
    }
});

export default function ReceiverForm() {
    const { setFormSteps, setSteps, steps, FormSteps } = useFormSteps();

    const formValues = useFormStore.getState().receiver;

    const methods = useForm<z.infer<typeof customerSchemaUpdated>>({
        resolver: zodResolver(customerSchemaUpdated),
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: formValues
    });

    const { prevStep, nextStep } = useStepper();

    const goPrev = () => {
        const data = methods.getValues();
        useFormStore.getState().addReceiver(data);
        prevStep();
    };

    const onSubmit = (data: z.infer<typeof customerSchemaUpdated>) => {
        console.log(data);
        useFormStore.getState().addReceiver(data);

        nextStep();
    };

    const handleSave = async (value: boolean) => {
        // console.log(value);
        useSaveAddressStore.getState().setSaveReceiver(value);
    };

    return (
        <Card>
            <CardHeader className="p-4">
                <FormTitle>Receiver Address</FormTitle>
            </CardHeader>
            <CardContent className="p-4">
                <Form methods={methods} onSubmit={onSubmit} className="grid gap-6">
                    <CustomerContext.Provider value="receiver">
                        <AddressForm />
                    </CustomerContext.Provider>
                    <div>
                        <Checkbox name="saveAddress" label="Save this address in address book" onChange={handleSave} defaultValue={useSaveAddressStore.getState().saveReceiver} />
                    </div>
                    <div className="flex gap-6">
                        <Button type="button" onClick={goPrev} variant="outline" className="flex-grow">
                            Back
                        </Button>

                        <Button variant="default" type="submit" className="flex-grow">
                            Next
                        </Button>
                    </div>
                </Form>
            </CardContent>
        </Card>
    );
}
