import { parsePhoneNumber } from "awesome-phonenumber";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { validate } from "postal-codes-js";
import { z } from "zod";
dayjs.extend(isBetween);

const addressSchema = z
    .object({
        postalCode: z.string().min(1, "Postal Code is required").toUpperCase(),
        countryCode: z.string().min(1, "Country Code is required").toUpperCase(),
        city: z.string().min(1, "City is required"),
        stateCode: z.string().min(1, "State is required").toUpperCase(),
        street: z.string().min(1, "Street is required"),
        line2: z.string().optional(),
        residential: z.boolean().optional()
    })
    .superRefine((data, ctx) => {
        if (validate(data.countryCode, data.postalCode) !== true) {
            ctx.addIssue({
                code: "custom",
                message: validate(data.countryCode, data.postalCode) as string,
                path: ["postalCode"],
                fatal: true
            });
        }
    });

export const dateSchema = z.object({
    date: z.string().optional(),
    time: z.string().optional(),
    isoTimeStamp: z.string().optional()
});
export const customerSchema = z
    .object({
        phoneNumber: z.string(),
        companyName: z.string().min(1, "Company Name is required").max(34, "Company Name is too long").optional().or(z.literal("")),
        attentionName: z.string().min(1, "Attention Name is required").max(34, "Attention Name is too long"),
        email: z.string().email().min(1, "Email is required").optional().or(z.literal("")),
        address: addressSchema,
        // notifications: z.boolean().optional()
        // notifications: z.array(z.string())
        notifications: z.array(z.enum(["delivered", "requiresAttention", "inTransit", "pickedUp", "shipmentCreated"])).optional()
    })

    .superRefine((data, ctx) => {
        if (parsePhoneNumber(data.phoneNumber).valid === false) {
            if (data.phoneNumber.startsWith("+")) {
                ctx.addIssue({
                    code: "custom",
                    message: "Please add a valid phone number",
                    path: ["phoneNumber"],
                    fatal: true
                });
            } else {
                ctx.addIssue({
                    code: "custom",
                    message: "Please verify that you have included a country code (e.g., '+1')",
                    path: ["phoneNumber"],
                    fatal: true
                });
            }
        }
    });

const packageDimensionsSchema = z.object({
    weight: z.number({ invalid_type_error: "missing number" }).min(0.1, "Must include a value"),
    length: z.number().min(0, "Must include a value"),
    width: z.number().min(0, "Must include a value"),
    height: z.number().min(0, "Must include a value"),
    batteries: z.boolean().optional(),
    description: z.string().optional()
});

const productDetailsSchema = z.object({
    description: z.string(),
    quantity: z.number().min(1, "Quantity needs to have a value"),
    units: z.string(),
    numberOfPackages: z.number().min(1, "Number of Packages needs to have a value"),
    value: z.number().min(1, "Value needs to have a value"),
    weight: z.number().min(1, "Weight needs to have a value"),
    country: z.string(),
    HSCode: z.string().optional(),
    SKU: z.string().optional()
});

export const customsDetailsSchema = z
    .object({
        exportReason: z.string(),
        products: z.array(productDetailsSchema),
        customsCurrency: z.string(),
        customsPayor: z.string(),
        comments: z.string().optional(),
        declarationStatement: z.string().optional(),
        declarationStatementText: z.string().optional(),
        confirmation: z.boolean().optional()
    })
    .refine(
        (data) => {
            if (data.exportReason === "noValue") {
                return data.confirmation;
            }
            return true;
        },
        {
            message: "Please confirm this shipment only contains documents",
            path: ["confirmation"]
        }
    );

export const pickupDetailsSchema = z
    .object({
        pickupType: z.enum(["pickup", "dropoff"], { required_error: "Select a Pickup Type" }),
        scheduleType: z.enum(["scheduleNow", "scheduleLater", "useExisting"], { required_error: "Select a Schedule Type" }).optional(),
        carrier: z.enum(["UPS", "Purolator", "FedEx", "GLS"]).optional(),
        pickupLocation: z.string().optional(),
        readyTimeStamp: dateSchema.optional(),
        closeTimeStamp: dateSchema.optional(),
        specialNote: z.string().optional()
    })
    .refine(
        (data) => {
            const now = dayjs();
            const readyTime = dayjs(data.readyTimeStamp?.isoTimeStamp);
            return !data.readyTimeStamp?.isoTimeStamp || readyTime.isAfter(now.add(14, "minute"));
        },
        {
            message: "Ready time must be at least 15 minutes from now.",
            path: ["readyTime"]
        }
    )
    .superRefine((data, ctx) => {
        if (data.pickupType === "pickup" && data.scheduleType === "scheduleNow") {
            const readyTime = dayjs(data.readyTimeStamp?.isoTimeStamp);
            const closeTime = dayjs(data.closeTimeStamp?.isoTimeStamp);

            if (!data.readyTimeStamp?.isoTimeStamp || !data.closeTimeStamp?.isoTimeStamp) {
                return;
            }

            if (readyTime.isAfter(closeTime)) {
                ctx.addIssue({
                    code: "custom",
                    message: "Ready time must be before close time.",
                    path: ["readyTime"]
                });
            }

            switch (data.carrier) {
                case "UPS":
                    if (closeTime.isBefore(closeTime.startOf("day").set("hour", 13))) {
                        ctx.addIssue({
                            code: "custom",
                            message:
                                "Please set the pick-up window close time to after 1:00 PM, as per UPS guidelines. For reference, UPS typically completes drop-offs in the morning and schedules pick-ups in the afternoon.",
                            path: ["closeTime"]
                        });
                    }
                    if (closeTime.diff(readyTime, "hour", true) < 3) {
                        ctx.addIssue({
                            code: "custom",
                            message: "UPS requires a 3-hour pick-up window to ensure the driver has sufficient time to collect your shipment.",
                            path: ["closeTime"]
                        });
                    }
                    break;

                case "Purolator":
                    if (closeTime.diff(readyTime, "hour", true) < 2) {
                        ctx.addIssue({
                            code: "custom",
                            message: "Purolator requires a minimum 2-hour pick-up window to ensure the driver has sufficient time to collect your shipment.",
                            path: ["closeTime"]
                        });
                    }
                    break;

                case "FedEx":
                    if (readyTime.isAfter(readyTime.startOf("day").set("hour", 17))) {
                        ctx.addIssue({
                            code: "custom",
                            message: "Please ensure the pick-up window 'ready time' is set before 5:00 PM, as required by FedEx.",
                            path: ["readyTime"]
                        });
                    }
                    if (closeTime.diff(readyTime, "hour", true) < 1.5) {
                        ctx.addIssue({
                            code: "custom",
                            message: "FedEx requires a minimum 1.5-hour pick-up window to ensure the driver has sufficient time to collect your shipment.",
                            path: ["closeTime"]
                        });
                    }
                    break;
                case "GLS":
                    if (readyTime.isAfter(readyTime.startOf("day").set("hour", 20).set("minute", 30))) {
                        ctx.addIssue({
                            code: "custom",
                            message: "Please ensure the pick-up window 'ready time' is set before 8:30 PM, as required by GLS.",
                            path: ["readyTime"]
                        });
                    }
                    if (closeTime.isAfter(closeTime.startOf("day").set("hour", 22).set("minute", 30))) {
                        ctx.addIssue({
                            code: "custom",
                            message: "Please ensure the pick-up window 'close time' is set before 10:30 PM, as required by GLS.",
                            path: ["closeTime"]
                        });
                    }
                    if (closeTime.diff(readyTime, "hour", true) < 1.5) {
                        ctx.addIssue({
                            code: "custom",
                            message: "GLS requires a 1.5-hour pick-up window to ensure the driver has sufficient time to collect your shipment.",
                            path: ["closeTime"]
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    });

export const shipmentDetailsSchema = z
    .object({
        deliveryFormat: z.string({ required_error: "Please select a delivery format" }),
        shipmentType: z.enum(["Package", "Letter", "Tube"], { required_error: "Select a Shipment Type" }),
        units: z.enum(["metric", "imperial"], { required_error: "Select Units" }),
        packages: z.array(packageDimensionsSchema),
        signature: z.string().optional(),
        description: z.string().min(1, "Description of Shipment is needed").max(25, "Character limit must be less than 25 characters."),
        confirmation: z.boolean().optional(),
        returnDetails: z
            .object({
                isReturnShipment: z.boolean(),
                reason: z.string().optional()
            })
            .optional(),
        printerType: z.enum(["regular", "thermal"])
    })
    .refine(
        (data) => {
            if (data.shipmentType === "Letter" && data.deliveryFormat === "pak") {
                if (data.packages[0].weight > 1.36 && data.units === "metric") {
                    return false;
                }
                if (data.packages[0].weight > 3 && data.units === "imperial") {
                    return false;
                }
            }
            return true;
        },
        {
            message: "Please note that the weight limit for a courier pak shipment is 3 lbs or 1.36 kg",
            path: ["packages[0].weight"]
        }
    )
    .refine(
        (data) => {
            if (data.shipmentType === "Letter" && data.deliveryFormat === "letter") {
                if (data.packages[0].weight > 0.4 && data.units === "metric") {
                    return false;
                }
                if (data.packages[0].weight > 1 && data.units === "imperial") {
                    return false;
                }
            }
            return true;
        },
        {
            message: "Please note that the weight limit for a letter shipment is 1 lbs or 0.4 kg",
            path: ["deliveryFormat"]
        }
    )
    .refine(
        (data) => {
            if (data.shipmentType === "Letter" && data.deliveryFormat === "letter") {
                return data.confirmation;
            }
            return true;
        },
        {
            message: "Please confirm this envelope only contains documents",
            path: ["confirmation"]
        }
    );

export const shipmentSchema = z.object({
    shipper: customerSchema,
    pickupDetails: pickupDetailsSchema,
    receiver: z.array(customerSchema),
    shipmentDetails: shipmentDetailsSchema,
    customsDetails: customsDetailsSchema.optional()
});

export const GetQuoteSchema = z.object({
    shipmentDetails: shipmentDetailsSchema,
    shipperAddress: addressSchema,
    receiverAddress: addressSchema
});
