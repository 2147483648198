import { useEffect, useState } from "react";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable, getPaginationRowModel, SortingState, getSortedRowModel, ColumnFiltersState, getFilteredRowModel } from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ChevronLeft, ChevronRight, ChevronsRight, ChevronsLeft, PackagePlus, RotateCw } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    refreshData: () => void;
    loading: boolean;
    page: number;
    pageSize: number;
    setPage: Function;
    setPageSize: Function;
}

export function DataTable<TData, TValue>({ columns, data, refreshData, loading, page, pageSize, setPage, setPageSize }: DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = useState<SortingState>([{ id: "date", desc: true }]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = useState({});
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [refreshDisabled, setRefreshDisabled] = useState(false);
    const navigate = useNavigate();
    const { status } = useParams();

    const paginatedData = data.slice(page * pageSize, (page + 1) * pageSize);

    const table = useReactTable({
        data: paginatedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        onRowSelectionChange: setRowSelection,
        manualPagination: true,
        pageCount: Math.ceil(data.length / pageSize),
        state: {
            sorting,
            columnFilters,
            rowSelection
        },
        initialState: {
            pagination: {
                pageIndex: page,
                pageSize: pageSize
            }
        }
    });

    useEffect(() => {
        status && table.getColumn("status")?.setFilterValue(status);
    }, [status]);

    return (
        <div>
            <div className="flex items-center justify-between pb-4 ">
                <Input
                    placeholder="Search by shipment ID"
                    value={(table.getColumn("id")?.getFilterValue() as string) ?? ""}
                    onChange={(event) => table.getColumn("id")?.setFilterValue(event.target.value)}
                    className="h-8 w-[150px] lg:w-[250px]"
                />
                <div className="flex space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        className="ml-auto hidden h-8 lg:flex"
                        disabled={refreshLoading || refreshDisabled}
                        onClick={async () => {
                            setRefreshLoading(true);
                            setRefreshDisabled(true);
                            try {
                                await refreshData();
                            } catch {
                                toast({ title: "Error refreshing data", variant: "destructive" });
                            } finally {
                                setTimeout(() => {
                                    setRefreshLoading(false);
                                    toast({ title: "Data successfully refreshed", className: "bg-success-500 text-white" });
                                }, 4000); // give time for the firebase docs to update
                                setTimeout(() => setRefreshDisabled(false), 30000); // 30 seconds delay
                            }
                        }}>
                        <RotateCw className={`mr-2 h-4 w-4 ${refreshLoading ? " animate-spin" : ""}`} />
                        Refresh
                    </Button>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" size="sm" className="ml-auto hidden h-8 lg:flex">
                                <MixerHorizontalIcon className="mr-2 h-4 w-4" />
                                View
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="w-[150px]">
                            <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {table
                                .getAllColumns()
                                .filter((column) => typeof column.accessorFn !== "undefined" && column.getCanHide())
                                .map((column) => {
                                    return (
                                        <DropdownMenuCheckboxItem key={column.id} className="capitalize" checked={column.getIsVisible()} onCheckedChange={(value) => column.toggleVisibility(!!value)}>
                                            {column.id === "customerName" ? "Name" : column.id === "id" ? "ID" : column.id === "trackingNumber" ? "Tracking #" : column.id}
                                        </DropdownMenuCheckboxItem>
                                    );
                                })}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return <TableHead key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</TableHead>;
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {!loading ? (
                            table.getRowModel().rows?.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={columns.length} className=" h-24 text-center">
                                        <p className="text-lg">You have no shipments.</p>
                                        <Button variant="outline" className="mt-3" onClick={() => navigate("/create-shipment")}>
                                            <PackagePlus className="mr-2 h-4 w-4" />
                                            Create a shipment!
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                table.getRowModel().rows.map((row, index) => (
                                    <TableRow key={index} data-state={row.getIsSelected() && "selected"} onClick={() => navigate(`/shipments/id/${row.getValue("id")}`)} className="cursor-pointer">
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            )
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className=" h-24 text-center">
                                    {/* <p className="text-lg">You have no shipments.</p>
                                    <Button variant="outline" className="mt-3" onClick={() => navigate("/create-shipment")}>
                                        <PackagePlus className="mr-2 h-4 w-4" />
                                        Create a shipment!
                                    </Button> */}
                                    <p className="text-lg">Loading...</p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="text-muted-foreground flex-1 text-sm">{/* {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s) selected. */}</div>
                <div className="flex items-center space-x-2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                        value={`${table.getState().pagination.pageSize}`}
                        onValueChange={(value) => {
                            const newPageSize = Number(value);
                            setPageSize(newPageSize);
                            setPage(0); // required or pages get messed up
                            table.setPageSize(newPageSize);
                            table.setPageIndex(0);
                        }}>
                        <SelectTrigger className="h-8 w-[70px]">
                            <SelectValue placeholder={table.getState().pagination.pageSize} />
                        </SelectTrigger>
                        <SelectContent side="top">
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <SelectItem key={pageSize} value={`${pageSize}`}>
                                    {pageSize}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                    Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                </div>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        setPage(0);
                        return table.setPageIndex(0);
                    }}
                    disabled={!table.getCanPreviousPage()}>
                    <span className="sr-only">Go to first page</span>
                    <ChevronsLeft className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        setPage(page - 1);
                        return table.previousPage();
                    }}
                    disabled={!table.getCanPreviousPage()}>
                    <ChevronLeft className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        setPage(page + 1);
                        return table.nextPage();
                    }}
                    disabled={!table.getCanNextPage()}>
                    <span className="sr-only">Go to next page</span>
                    <ChevronRight className="h-4 w-4" />
                </Button>
                <Button
                    variant="outline"
                    className="hidden h-8 w-8 p-0 lg:flex"
                    onClick={() => {
                        const lastPage = table.getPageCount() - 1;
                        setPage(lastPage);
                        return table.setPageIndex(lastPage);
                    }}
                    disabled={!table.getCanNextPage()}>
                    <span className="sr-only">Go to last page</span>
                    <ChevronsRight className="h-4 w-4" />
                </Button>
            </div>
        </div>
    );
}
