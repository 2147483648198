import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Address, Customer } from "@shared/ShipmentTypes";
import React, { ChangeEventHandler, createContext, useContext, useEffect, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import AddressAutocomplete from "./Inputs/AddressAutocomplete";
import Checkbox from "./Inputs/Checkbox";
import Input from "./Inputs/Input";
import { getAddresses } from "@/api/addresses";
import Separator from "@/components/composite/Separator";
import { useDropdownStore } from "@/core/Dropdown";
import { useUserStore } from "@/core/UserStore";
import { CircleAlert } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { FormDescription } from "../ui/form";
import { useStepper } from "../ui/stepper";
import CountryDropdown from "./Inputs/CountryInputs/CountryInput";
import StateDropdown from "./Inputs/CountryInputs/StateInput";
import PhoneNumber from "./Inputs/PhoneNumber";
import Select, { SelectItem } from "./Inputs/Select";
import { MultiSelect, MultiSelectOptions } from "../ui/multi-select";
import { useAddressBookStore } from "@/core/AddressBookStore";
import { useSaveAddressStore } from "@/core/SaveAddress";

export const CustomerContext = createContext<"shipper" | "receiver" | undefined>(undefined);

export default function AddressForm() {
    const {
        setValue,
        getValues,
        formState: { errors },
        reset
    } = useFormContext();

    const navigate = useNavigate();

    const customer = useContext(CustomerContext);

    const [addressList, setAddressList] = useState<Customer[]>();
    const [dropdown, setDropdown] = useState<SelectItem[]>();

    const userData = useUserStore((state) => state.userData);

    const addressBook = useAddressBookStore((state) => state.addressBook);

    const { setCountryValue, setStateValue } = useDropdownStore();

    useEffect(() => {
        console.log(addressBook);
        if (addressBook) {
            setAddressList(addressBook);
            const dropdownList: SelectItem[] = addressBook.map((customer, index) => {
                const addressDetails = [customer.address.street, customer.address.city, customer.address.postalCode, customer.address.stateCode, customer.address.countryCode].join(" ");
                return {
                    name: index.toString(),
                    label: `${customer.attentionName} — ${addressDetails}`
                };
            });
            setDropdown(
                dropdownList.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                })
            );
        }
    }, [addressBook]);

    const shipperIndex = useSaveAddressStore((state) => state.shipperIndex);
    const receiverIndex = useSaveAddressStore((state) => state.receiverIndex);

    let defaultValue: string = "";

    customer === "shipper" && shipperIndex && (defaultValue = shipperIndex.toString());
    customer === "receiver" && receiverIndex && (defaultValue = receiverIndex.toString());

    const handleAddressBookChange = async (value: number) => {
        if (addressList) {
            customer === "shipper" && useSaveAddressStore.getState().setShipperIndex(value);
            customer === "receiver" && useSaveAddressStore.getState().setReceiverIndex(value);
            reset(addressList[value]);
            setCountryValue(addressList[value].address.countryCode);
            setStateValue(addressList[value].address.stateCode);
        }
    };

    const handleNavigate = () => {
        navigate("/address", { replace: true });
    };

    return (
        <>
            {dropdown && (
                <>
                    <div className="grid grid-cols-[4fr_1fr] items-end gap-4">
                        <Select
                            SelectItemList={dropdown}
                            name="addressBook"
                            label="Select an address from your address book"
                            onChange={handleAddressBookChange}
                            placeholder="Use Address Book"
                            defaultValue={defaultValue}
                        />
                        <Button className="mb-2" type="button" onClick={handleNavigate} variant="secondary">
                            Manage Address Book
                        </Button>
                    </div>
                    <Separator text="or" />
                </>
            )}
            <AddressInfo />
        </>
    );
}

const notificationsList: MultiSelectOptions = [
    { value: "shipmentCreated", label: "Shipment Created" },
    { value: "pickedUp", label: "Picked Up" },
    { value: "inTransit", label: "In Transit" },
    { value: "requiresAttention", label: "Requires Attention" },
    { value: "delivered", label: "Delivered" }
];

export function BusinessInfo() {
    const customer = useContext(CustomerContext);

    let notificationPlaceholder: string;
    if (customer === "shipper") {
        notificationPlaceholder = "Do you want to receive email notifications as the sender?";
    } else if (customer === "receiver") {
        notificationPlaceholder = "Do you want the receiver to receive email notifications for this shipment?";
    } else {
        notificationPlaceholder = "Do you want this email address to receive email notifications for this shipment?";
    }

    const {
        getValues,
        setValue,
        formState: { errors },
        watch
    } = useFormContext();

    const { activeStep } = useStepper();

    const emailLabel = activeStep === 1 && getValues("address.countryCode") !== "CA" ? "Email" : "Email (optional)";

    const [showNotifications, setShowNotification] = useState(false);

    // check using getValues and watch for live and after they are done typing/address book
    useEffect(() => {
        const email = getValues("email");
        setShowNotification(!!email);
        const { unsubscribe } = watch(({ email }) => {
            setShowNotification(!!email);
        });
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [getValues, watch]);

    const handleNotificationsChange = (value: string[]) => {
        setValue("notifications", value);
    };

    const notificationsErrorMessages = get(errors, "notifications")?.messages;

    return (
        <>
            <Input label={"Business/Company Name (optional)"} name={"companyName"} />
            <div className="grid w-full max-w-full grid-cols-2 gap-4 overflow-x-auto">
                <Input label={customer === "shipper" ? "Contact Name" : "Attention Name"} name={"attentionName"} />
                <div className="grid w-full gap-2">
                    <Input label={emailLabel} name={"email"} type={"email"} className="w-full" />
                    {showNotifications && (
                        <div className="w-full">
                            <MultiSelect
                                name="notifications"
                                options={notificationsList}
                                onValueChange={handleNotificationsChange}
                                defaultValue={getValues("notifications")}
                                placeholder={notificationPlaceholder}
                                maxCount={2}
                                className="max-w-full"
                            />
                            {notificationsErrorMessages ? (
                                <p role="error" className="text-error-500 text-xs">
                                    {notificationsErrorMessages}
                                </p>
                            ) : (
                                <p className="block text-xs text-red-500"> </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <PhoneNumber />
        </>
    );
}

export function AddressInfo() {
    const {
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext();

    const [address, setAddress] = useState<Address>({
        street: getValues("address.street"),
        city: getValues("address.city"),
        postalCode: getValues("address.postalCode"),
        stateCode: getValues("address.stateCode"),
        countryCode: getValues("address.countryCode")
    });

    const { setCountryValue, setStateValue } = useDropdownStore();

    const [showInput, setShowInput] = useState(false);

    // console.log(address);

    useEffect(() => {
        setAddress(getValues("address"));
    }, [getValues("address")]);

    useEffect(() => {
        address.street !== "" && setValue("address.street", address.street);
        address.city !== "" && setValue("address.city", address.city);
        address.postalCode !== "" && setValue("address.postalCode", address.postalCode);
        address.stateCode !== "" && setValue("address.stateCode", address.stateCode);
        address.countryCode !== "" && setValue("address.countryCode", address.countryCode);

        setShowInput(address.street ? address.street !== "" : false);

        setCountryValue(address.countryCode);
        setStateValue(address.stateCode);
    }, [address]);

    // console.log(getValues());
    const handleClick = () => {
        setShowInput(!showInput);
    };

    const residentialDefaultValue = getValues("address.residential") || false;

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setShowInput(true);
        }
    }, [errors]);

    const AllInputs = () => {
        return <AddressDetails residentialDefaultValue={residentialDefaultValue} />;
    };

    return (
        <>
            {!showInput && (
                <>
                    <Label>Type in Address</Label>
                    <AddressAutocomplete address={address} setAddress={setAddress} />
                </>
            )}
            {showInput && <AllInputs />}
            <div className="grid w-full place-content-center">
                <Button type="button" variant="link" onClick={handleClick} className="m-auto">
                    {showInput ? "Show Autocomplete Input" : "Edit address manually"}
                </Button>
            </div>
        </>
    );
}

export function AddressDetails({ residentialDefaultValue }: { residentialDefaultValue?: boolean }) {
    return (
        <>
            <Input label={"Address"} name={"address.street"} />
            <div className="grid gap-2">
                <Input label={"Address Line 2 (optional)"} name={"address.line2"} />
                <FormDescription className="flex items-center">
                    <CircleAlert className="h-4" />
                    PO Box addresses are not accepted.
                </FormDescription>
            </div>
            <Input label={"City"} name={"address.city"} />
            <div className="grid grid-cols-[1fr_2fr_2fr] gap-4">
                <Input label={"Postal Code"} name={"address.postalCode"} />
                <CountryDropdown label={"Country"} name={"address.countryCode"} />
                <StateDropdown label={"State"} name={"address.stateCode"} />
            </div>
            <Checkbox name="address.residential" label="Is Residential Address" defaultValue={residentialDefaultValue} />
            <BusinessInfo />
        </>
    );
}
