import { db } from "@/core/firebase";
import { collection, getDocs, limit, orderBy, query, where, doc, getDoc } from "firebase/firestore";

import { User } from "firebase/auth";
import { Shipment } from "@shared/ShipmentTypes";
import { PickupData } from "@shared/PickupTypes";

export const getPickupByID = async (id: string | undefined) => {
    // * pickup ID
    if (id === undefined) {
        console.error("id is invalid.");
        return null;
    }
    // console.log(id);
    const pickupRef = doc(db, "pickups", id);
    const pickup = await getDoc(pickupRef);
    if (pickup.exists()) {
        console.log(pickup.data());
        return pickup.data() as PickupData;
    } else {
        console.log("No pickup found");
        return null;
    }
};

export const getPickupByShipmentID = async (shipmentID: string | undefined) => {
    if (!shipmentID) throw new Error("Undefined shipment ID; unable to cancel pickup.");

    const pickupRef = collection(db, "pickups");
    // const queryFirestore = query(pickupRef, where("shipmentID", "==", shipmentID));
    const queryFirestore = query(pickupRef, where("shipmentID", "array-contains", shipmentID));
    const pickup = await getDocs(queryFirestore);
    if (pickup.empty) throw new Error("No associated pickup found.");

    return pickup.docs[0].data() as PickupData;
};

export const getAllPickups = async (uid: string | undefined) => {
    if (uid === undefined) {
        throw new Error("missing ID");
    }

    const pickupRef = collection(db, "pickups");
    const queryFirestore = query(pickupRef, where("uid", "==", uid));

    try {
        const pickupQuery = await getDocs(queryFirestore);
        if (!pickupQuery.empty) {
            const pickups: PickupData[] = pickupQuery.docs.map((pickup) => {
                return pickup.data() as PickupData;
            });
            return pickups;
         }

         return []
    } catch(e) {
        console.error("Error in getAllPickups:", e)
        return []
    }
};
