// sentry init
import * as Sentry from "@sentry/react";

import React from "react";
import ReactDOM from "react-dom/client";

import "./styles/globals.css";
import "./styles/index.css";

// posthog init
import posthog from "posthog-js";
import App from "./app";
import { BrowserRouter } from "react-router-dom";

// posthog.init("phc_ISDbLmTz3e9IiSlmkpN9VrMtWsbOei1q0n9CfFQTPe9", {
//     api_host: "https://us.i.posthog.com"
// });

Sentry.init({
    dsn: "https://9db4746f1142ad563c271546ce82f965@o4507228554592256.ingest.us.sentry.io/4507637014921216",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.MODE,
    enabled: import.meta.env.MODE === "production"
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);
