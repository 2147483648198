import { useState, useEffect } from "react";
import PageTitle from "@/components/composite/Headers/PageTitle";
import { Package } from "lucide-react";
import { columns } from "./columns";
import { DataTable } from "./data-table";
import { useUserStore } from "@/core/UserStore";
import { Shipment, ShipmentsTableHeader } from "@shared/ShipmentTypes";
import { collection, getDocs, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "@/core/firebase";
import { updateShipmentsStatuses } from "@/api/shipments/updateShipment";

export default function ShipmentsLayout() {
    const [data, setData] = useState<ShipmentsTableHeader[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const user = useUserStore((state) => state.user);

    // setup real time doc updates
    useEffect(() => {
        if (user) {
            const q = query(collection(db, "shipments"), where("uid", "==", user.email), orderBy("date", "desc"));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const shipments = snapshot.docs.map((doc) => {
                    const data = doc.data() as Shipment;

                    return {
                        id: doc.id,
                        carrier: data.serviceDetails?.carrier || "N/A",
                        customerName: data.receiver[0].attentionName,
                        status: data.status || "Label Created",
                        trackingNumber: data.trackingNumber || "N/A",
                        date: data.date,
                    };
                });

                setData(shipments);
                setLoading(false);
            });

            return () => unsubscribe();
        }
    }, [user]);

    async function updateShipmentStatuses(shipments: ShipmentsTableHeader[], _page: number, _pageSize: number) {
        // Trigger backend update for shipment statuses
        const shipmentIds: string[] = []
        const startInclusive = _page * _pageSize
        const endExclusive = (_page + 1) * _pageSize

        for (let i = startInclusive; i < endExclusive; i++) {
            shipmentIds.push(shipments[i].id)
        }
        await updateShipmentsStatuses(shipmentIds);
    }

    // Fetch data and trigger status updates
    async function fetchData() {
        if (user) {
            try {
                // TODO: paginate this as well
                const q = query(collection(db, "shipments"), where("uid", "==", user.email), orderBy("date", "desc"));

                const snapshot = await getDocs(q);
                const shipments: ShipmentsTableHeader[] = [];

                snapshot.forEach((doc) => {
                    const data = doc.data() as Shipment;

                    shipments.push({
                        id: doc.id,
                        carrier: data.serviceDetails?.carrier || "N/A",
                        customerName: data.receiver[0].attentionName,
                        status: data.status || "Label Created",
                        trackingNumber: data.trackingNumber || "N/A",
                        date: data.date,
                    });
                });

                setData(shipments);
                setLoading(false);
                updateShipmentStatuses(shipments, page, pageSize)
            } catch (error) {
                console.error("Error fetching data: ", error);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [user]);

    const _setPage = (_page: number) => {
        updateShipmentStatuses(data, _page, pageSize)
        setPage(_page)
    }

    // TODO: why does this call update shipment statuses twice (once with old value)?
    const _setPageSize = (_pageSize: number) => {
        updateShipmentStatuses(data, page, _pageSize)
        setPageSize(_pageSize)
    }

    return (
        <>
            <PageTitle>
                <Package />
                Shipments
            </PageTitle>
            <DataTable columns={columns} data={data} refreshData={fetchData} loading={loading} page={page} pageSize={pageSize} setPage={_setPage} setPageSize={_setPageSize} />
        </>
    );
}
