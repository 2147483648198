import AddressForm, { AddressDetails, AddressInfo } from "@/components/composite/AddressForm";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { customerSchema } from "@/schema/ShipmentSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import Form from "@/components/composite/Form";
import { addAddress, editAddress, removeAddress } from "@/api/addresses";
import { useUserStore } from "@/core/UserStore";
import { Customer } from "@shared/ShipmentTypes";
import { useDropdownStore } from "@/core/Dropdown";
import { Button } from "@/components/ui/button";
import { useAddressBookStore } from "@/core/AddressBookStore";

interface AddressDialog {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AddAddressDialog({ open, setOpen }: AddressDialog) {
    const [isLoading, setIsLoading] = useState(false);

    const userData = useUserStore((state) => state.userData);

    const methods = useForm<z.infer<typeof customerSchema>>({
        resolver: zodResolver(customerSchema),
        mode: "onChange"
    });

    useEffect(() => {
        methods.reset();
    }, []);

    const handleSubmit = async (data: z.infer<typeof customerSchema>) => {
        useAddressBookStore.getState().addAddress(data);
        methods.reset();
        setOpen(false);
    };
    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="max-h-[625px] overflow-y-auto sm:max-w-[925px]">
                    <DialogHeader>
                        <DialogTitle>Add Address</DialogTitle>
                        <DialogDescription>
                            <p>To add an address, fill out the form below </p>
                        </DialogDescription>
                    </DialogHeader>
                    <Form methods={methods} onSubmit={handleSubmit} className="grid gap-2">
                        <AddressInfo />
                        <LoadingButton isLoading={isLoading} type="submit">
                            Add Address
                        </LoadingButton>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
}

interface EditAddressDialog extends AddressDialog {
    defaultValues: Customer;
}

export function EditAddressDialog({ open, setOpen, defaultValues }: EditAddressDialog) {
    const [isLoading, setIsLoading] = useState(false);

    const userData = useUserStore((state) => state.userData);
    const { setCountryValue, setStateValue } = useDropdownStore();

    const methods = useForm<z.infer<typeof customerSchema>>({
        resolver: zodResolver(customerSchema),
        mode: "onChange",
        defaultValues: defaultValues
    });

    useEffect(() => {
        methods.reset(defaultValues);
        if (defaultValues.address) {
            methods.setValue("address.countryCode", defaultValues.address.countryCode);
            methods.setValue("address.stateCode", defaultValues.address.stateCode);
            setCountryValue(defaultValues.address.countryCode);
            setStateValue(defaultValues.address.stateCode);
        }
    }, [defaultValues]);

    const handleSubmit = async (data: z.infer<typeof customerSchema>) => {
        if (userData) {
            useAddressBookStore.getState().removeAddress(defaultValues);
            useAddressBookStore.getState().addAddress(data);
        }
        setOpen(false);
    };

    const handleCancel = async () => {
        if (userData) {
            useAddressBookStore.getState().removeAddress(defaultValues);
        }
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="max-h-[625px] overflow-y-auto sm:max-w-[925px] ">
                    <DialogHeader>
                        <DialogTitle>Edit Address</DialogTitle>
                        <DialogDescription>
                            <p>To edit the address, edit the form below </p>
                        </DialogDescription>
                    </DialogHeader>
                    <Form methods={methods} onSubmit={handleSubmit} className="grid gap-2">
                        <AddressInfo />
                        <div className="grid grid-cols-2 gap-2">
                            <Button variant={"destructive"} type="reset" onClick={handleCancel}>
                                Remove Address
                            </Button>
                            <LoadingButton isLoading={isLoading} type="submit">
                                Save Changes
                            </LoadingButton>
                        </div>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
}
