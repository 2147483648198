import logo from "@/assets/logos/SnapShipLogoNEW.svg";
import { getAuth, getRedirectResult, GoogleAuthProvider, signInWithEmailAndPassword, signInWithRedirect } from "firebase/auth";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import Form from "@/components/composite/Form";
import { auth, db } from "@/core/firebase";
import { zodResolver } from "@hookform/resolvers/zod";
import { loginSchema } from "@/schema/UserSchema";
import { useUserStore } from "@/core/UserStore";
import Input from "@/components/composite/Inputs/Input";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { Label } from "@/components/ui/label";
import Trucks from "@/assets/misc/Trucks.svg";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { UserData } from "@shared/UserTypes";
import { createStripeCustomer } from "@/api/user/editStripeCustomer";
import { PasswordField } from "@/components/ui/password-input";
import { useAddressBookStore } from "@/core/AddressBookStore";
import { getAddresses } from "@/api/addresses";

export default function LoginForm({
    login = async (email: string, password: string) => {
        await signInWithEmailAndPassword(auth, email, password);
    }
}) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<z.infer<typeof loginSchema>>({
        resolver: zodResolver(loginSchema),
        mode: "all",
        reValidateMode: "onChange"
    });

    const onSubmit = async (data: z.infer<typeof loginSchema>) => {
        setIsLoading(true);
        try {
            await login(data.email, data.password);
            const addressBook = await getAddresses(data.email);
            if (addressBook) {
                useAddressBookStore.getState().addAddressBook(addressBook);
            }
            navigate("/", { replace: true });
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            let message = "";
            if (errorCode === "auth/wrong-password") {
                message = "Invalid password";
                methods.setError("password", { message });
            } else if (errorCode === "auth/invalid-credential") {
                message = "Invalid credential";
                methods.setError("root", { message });
            } else if (errorCode === "auth/user-not-found") {
                message = "User not found";
                methods.setError("email", { message });
            } else {
                message = `${errorCode} ${errorMessage}`;
                methods.setError("root", { message });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleSignUp = (e) => {
        e.preventDefault();
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        provider.addScope("https://www.googleapis.com/auth/userinfo.email");
        provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
        auth.useDeviceLanguage();
        signInWithRedirect(auth, provider);
    };

    useEffect(() => {
        const handleRedirectResult = async () => {
            try {
                const result = await getRedirectResult(auth);
                if (result) {
                    const user = result.user;
                    if (user.email) {
                        const userDoc = await getDoc(doc(db, "users", user.email));
                        if (!userDoc.exists()) {
                            const stripeID = user.email ? await createStripeCustomer(user.email, user.displayName as string, "Enter company name") : null;
                            const userData: UserData = {
                                name: user.displayName ?? "N/A",
                                companyName: "Enter company name",
                                email: user.email ?? "N/A",
                                phoneNumber: user.phoneNumber ?? "Enter phone number",
                                stripe: stripeID,
                                activeCarriers: {
                                    UPS: { enabled: false, dateSigned: null },
                                    FedEx: { enabled: false, dateSigned: null },
                                    Purolator: { enabled: false, dateSigned: null }
                                }
                            };
                            await setDoc(doc(db, "users", user.email), userData);
                            useUserStore.getState().setUser(user);
                            useUserStore.getState().setUserData(userData);
                        }
                    }
                    navigate("/", { replace: true });
                }
            } catch (error) {
                console.error(error.message);
            }
        };
        handleRedirectResult();
    }, [auth, navigate]);

    return (
        <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
            <div className="relative flex items-center justify-center bg-white py-12">
                <div className="absolute left-0 right-0 top-0 flex justify-center p-9 lg:justify-between">
                    <img src={logo} className="h-10 place-self-center object-cover" />
                </div>
                <div className="mx-auto mt-20 grid w-[350px] gap-6 lg:my-auto">
                    <div className="grid gap-2 text-center">
                        <h1 className="text-3xl font-bold">Login</h1>
                        <p className="text-muted-foreground text-balance">Enter your email and password below to login</p>
                    </div>
                    <Form methods={methods} onSubmit={onSubmit} className="grid gap-4">
                        <div className="grid gap-2">
                            <Label htmlFor="email">Email</Label>
                            <Input name="email" type="email" placeholder="Enter email" />
                        </div>
                        <div className="grid gap-2">
                            <Label htmlFor="password">Password</Label>
                            <Input name="password" type="password" placeholder="Enter password" />
                        </div>
                        {/* <PasswordField showForgotPassword={true} name="password" /> */}
                        <LoadingButton isLoading={isLoading} className="w-full" type="submit">
                            Login
                        </LoadingButton>
                        {/* <Button variant="outline" className="w-full" onClick={handleGoogleSignUp}>
                            <img src={GoogleIcon} alt="Google Logo" className="mr-2 h-5 w-5" />
                            Login with Google
                        </Button> */}
                    </Form>
                    <div className="mt-4 text-center text-sm">
                        Don&apos;t have an account?{" "}
                        <Link to="/sign-up" className="underline">
                            Sign up
                        </Link>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block" style={{ backgroundColor: "#FCFCFC" }}>
                <img src={Trucks} alt="" className="max-h-screen w-full" />
            </div>
        </div>
    );
}
