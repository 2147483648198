import PageTitle from "@/components/composite/Headers/PageTitle";
import { Button } from "@/components/ui/button";
import Card from "@/components/composite/Card";
import { ChevronLeftIcon } from "@radix-ui/react-icons";
import OrderDetails from "@/components/composite/OrderDetails";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Shipment } from "@shared/ShipmentTypes";
import LabelsDetails from "@/components/composite/LabelsDetails";
import { db } from "@/core/firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { toast } from "@/components/ui/use-toast";
import { getShipmentByID } from "@/api/shipments/getShipment";
import PickupDetails from "@/components/composite/Cards/PickupDetails";
import dayjs from "dayjs";
import { getPickupByID } from "@/api/pickups/getPickup";
import { PickupData } from "@shared/PickupTypes";
import SchedulePickup from "@/components/composite/Cards/SchedulePickup";
import { Skeleton } from "@/components/ui/skeleton";
import { removePickupFromShipment } from "@/api/pickups/updatePickups";

export default function ShipmentDetails() {
    const navigate = useNavigate();
    const { id } = useParams<string>();
    const [shipment, setShipment] = useState<Shipment>({} as Shipment);
    const [pickup, setPickup] = useState<PickupData>({} as PickupData);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id) {
            const getShipment = async () => {
                const _shipment = await getDoc(doc(db, "shipments", id));
                if (_shipment.exists()) {
                    const shipment = _shipment.data() as Shipment
                    setShipment(shipment)
                    setPickup({} as PickupData)
                    if (shipment.pickupDetails.pickupType === "pickup" && shipment.pickupDetails.id) {
                        getPickupByID(shipment.pickupDetails.id)
                            .then(async (pickupData: PickupData) => {
                                if (pickupData.status !== "cancelled") {
                                    setPickup(pickupData);
                                } else {
                                    await removePickupFromShipment(id);
                                }
                                console.log(pickupData);
                            })
                            .catch((error) => {
                                throw error;
                            });
                    }
                } else {
                    navigate("/shipments");
                    toast({
                        variant: "destructive",
                        title: "Shipment ID is not found."
                    });
                    console.error("Shipment ID is not found.");
                    return;
                }
                
                setLoading(false);
            }

            getShipment()

        } else {
            navigate("/shipments");
            toast({
                variant: "destructive",
                title: "Shipment ID is not provided."
            });
            console.error("Shipment ID is not provided.");
            return;
        }
    }, [id]);

    if (loading) {
        return <Skeleton className="h-full w-full" />;
    }

    return (
        <>
            <PageTitle>
                <Button variant="outline" size="icon" className="h-7 w-7" onClick={() => navigate("/shipments")}>
                    <ChevronLeftIcon className="h-4 w-4" />
                </Button>
                Shipment Details
            </PageTitle>
            <div className="flex gap-6">
                <div className="flex-1">
                    <OrderDetails type="details" shipment={shipment} />
                </div>

                <div className="flex w-1/3 flex-col gap-6">
                    {pickup.pickupDetails && <PickupDetails pickupData={pickup} shipment={shipment} setPickup={setPickup} setShipment={setShipment}/>}
                    {shipment.pickupDetails.id === null && <SchedulePickup shipment={shipment} setShipment={setShipment} setPickup={setPickup}/>}
                    <LabelsDetails shipment={shipment} />
                </div>
            </div>
        </>
    );
}
