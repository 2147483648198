import logo from "@/assets/logos/SnapShipLogoNEW.svg";
import { createUserWithEmailAndPassword, deleteUser, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import Form from "@/components/composite/Form";
import { auth, db } from "@/core/firebase";
import { zodResolver } from "@hookform/resolvers/zod";
import Input from "@/components/composite/Inputs/Input";
import { signUpSchema } from "@/schema/UserSchema";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { useState } from "react";
import { UserData } from "@shared/UserTypes";
import { useUserStore } from "@/core/UserStore";
import Trucks from "@/assets/misc/Trucks.svg";
import { createStripeCustomer } from "@/api/user/editStripeCustomer";
import { sendWelcomeEmail } from "@/api/emails/sendWelcomeEmail";
import { useAppContext } from "@/onboarding/context";

// create sign-in schema

export default function SignUpForm() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showPartnershipCodeInput, setShowPartnershipCodeInput] = useState(false);
    const {
        setState,
        state: { run, tourActive }
    } = useAppContext();

    const methods = useForm<z.infer<typeof signUpSchema>>({
        resolver: zodResolver(signUpSchema),
        mode: "all",
        reValidateMode: "onChange"
    });

    const onSubmit = (data: z.infer<typeof signUpSchema>) => {
        setIsLoading(true);
        createUserWithEmailAndPassword(auth, data.email, data.password)
            .then(async (userCredential) => {
                const user = userCredential.user; // created the user

                if (user !== null && user.email !== null) {
                    // if it fails after this point, it should delete the user from auth
                    updateProfile(user, { displayName: data.name }); // adds name to the user object in firebase auth

                    const stripeID = await createStripeCustomer(user.email, data.name, data.companyName);

                    let userData: UserData = {
                        name: data.name,
                        companyName: data.companyName,
                        email: user.email,
                        phoneNumber: data.phoneNumber,
                        stripe: stripeID,
                        activeCarriers: {
                            UPS: {
                                enabled: false,
                                dateSigned: null
                            },
                            FedEx: {
                                enabled: false,
                                dateSigned: null
                            },
                            Purolator: {
                                enabled: false,
                                dateSigned: null
                            },
                            GLS: {
                                enabled: false,
                                dateSigned: null
                            }
                        }
                    };

                    if (data.partnershipCode) {
                        userData = { ...userData, partnerships: data.partnershipCode };
                    }

                    if (user.email === null) {
                        // if the user email doesn't exist it should throw so that we don't get null in database
                        throw user;
                    }
                    await setDoc(doc(db, "users", user.email), userData);
                    await sendWelcomeEmail(user.email);
                    useUserStore.getState().setUser(user);
                    useUserStore.getState().setUserData(userData);
                    navigate("/settings/carriers", { replace: true });
                    setState({ run: true, tourActive: true, stepIndex: 0 });
                } else {
                    await deleteUser(user);
                    throw new Error("User creation failed");
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                let message: string = "";
                if (errorCode === "auth/email-already-in-use") {
                    message = "Email already in use";
                    methods.setError("email", {
                        message: message
                    });
                } else {
                    message = `${errorCode} ${errorMessage}`;
                    methods.setError("root", {
                        message: message
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const displayPartnershipCodeInput = () => {
        setShowPartnershipCodeInput(true);
    };

    // TODO: Implement Google Sign Up functionality
    // const handleGoogleSignUp = (e) => {
    //     const provider = new GoogleAuthProvider();
    //     const auth = getAuth();
    //     provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    //     provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    //     auth.useDeviceLanguage();
    //     signInWithRedirect(auth, provider);
    // };

    // getRedirectResult(auth)
    //     .then(async (result) => {
    //         console.log("Starting google auth..");
    //         console.log("Result:", result);
    //         if (result) {
    //             const credential = GoogleAuthProvider.credentialFromResult(result);
    //             const token = credential?.accessToken;

    //             const user = result.user;
    //             let stripeID;
    //             if (user.email !== null) {
    //                 stripeID = await createStripeCustomer(user.email);
    //             }
    //             const userData: UserData = {
    //                 // user: user,
    //                 name: user.displayName ?? "N/A",
    //                 companyName: "N/A",
    //                 email: user.email ?? "N/A",
    //                 phoneNumber: user.phoneNumber ?? "N/A",
    //                 stripe: stripeID,
    //                 activeCarriers: {
    //                     UPS: {
    //                         enabled: false,
    //                         dateSigned: null
    //                     },
    //                     FedEx: {
    //                         enabled: false,
    //                         dateSigned: null
    //                     },
    //                     Purolator: {
    //                         enabled: false,
    //                         dateSigned: null
    //                     }
    //                 }
    //             };
    //             if (user.email !== null) {
    //                 await setDoc(doc(db, "users", user.email), userData);
    //                 useUserStore.getState().setUser(user);
    //                 useUserStore.getState().setUserData(userData);
    //             } else {
    //                 throw new Error("User email is null");
    //             }
    //             navigate("/settings/carrier", { replace: true });

    //             setIsOpen(true);
    //             setCurrentStep(0);
    //         }
    //     })
    //     .catch((error) => {
    //         // Handle Errors here.
    //         const errorCode = error.code;
    //         const errorMessage = error.message;
    //         // The email of the user's account used.
    //         const email = error.customData.email;
    //         // The AuthCredential type that was used.
    //         const credential = GoogleAuthProvider.credentialFromError(error);
    //         console.error(errorCode, errorMessage);
    //         // ...
    //     });

    return (
        <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
            <div className="relative flex items-center justify-center bg-white py-12">
                <div className="absolute left-0 right-0 top-0 flex justify-center px-9 py-9 lg:justify-between">
                    <img src={logo} className="h-10 place-self-center object-cover" />
                </div>
                <div className="mx-auto mt-20 grid w-[350px] gap-6 lg:my-auto">
                    <div className="grid gap-2 text-center">
                        <h1 className="text-3xl font-bold">Sign Up</h1>
                        <p className="text-muted-foreground text-balance">Fill in the form below to sign up for free</p>
                    </div>
                    <Form methods={methods} onSubmit={onSubmit} className="grid gap-4">
                        <div className="grid grid-cols-2 gap-4">
                            <Input label="Name" name="name" type="text" placeholder="John O'Brien" />
                            <Input label="Company name" name="companyName" type="text" placeholder="Scrub Co" />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <Input label="Email" name="email" type="email" placeholder="m@example.com" />
                            <Input label="Phone number" name="phoneNumber" type="text" placeholder="1234567890" />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <Input label="Password" name="password" type="password" placeholder="Enter password" />
                            <Input label="Confirm password" name="confirmPassword" type="password" placeholder="Re-enter password" />
                        </div>
                        {showPartnershipCodeInput && <Input label="Partnership code (optional)" name="partnershipCode" placeholder="Enter partnership code" />}
                        {!showPartnershipCodeInput && (
                            <div className="text-muted-foreground text-sm">
                                Have a partnership code? Click{" "}
                                <span onClick={displayPartnershipCodeInput} style={{ textDecoration: "underline", color: "blue" }} className="cursor-pointer">
                                    here
                                </span>{" "}
                                to use it.
                            </div>
                        )}
                        <LoadingButton isLoading={isLoading} className="w-full" type="submit">
                            Sign up
                        </LoadingButton>
                        {/* <Button
                            variant="outline"
                            className="w-full"
                            onClick={(e) => {
                                e.preventDefault();
                                handleGoogleSignUp(e);
                            }}>
                            <img src={GoogleIcon} alt="Google Logo" className="mr-2 h-5 w-5" />
                            Sign up with Google
                        </Button> */}
                    </Form>
                    <div className="mt-4 text-center text-sm">
                        Already have an account?{" "}
                        <Link to="/login" className="underline">
                            Log in
                        </Link>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block" style={{ backgroundColor: "#FCFCFC" }}>
                <img src={Trucks} alt="" className="max-h-screen w-full" />
            </div>
        </div>
    );
}
