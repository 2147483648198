import RatesCard from "@/components/composite/Cards/RatesCard";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import useRatesStore from "@/core/RatesStore";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useStepper } from "@/components/ui/stepper";
import { useFormStore } from "@/core/ShipmentFormStore";
import { useFormSteps } from "@/context/FormStepContext";
import { FedExRates, GLSRates, PurolatorRates, UPSRates } from "@/api/rates";
import { Skeleton } from "@/components/ui/skeleton";
import { RatesResponse } from "@/types/ResponseTypes";
import { useUserStore } from "@/core/UserStore";
import { useToast } from "@/components/ui/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { useNavigate } from "react-router-dom";
import { validateAddress } from "@/api/addresses";
import { Address, Shipment } from "@shared/ShipmentTypes";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Partnerships } from "@shared/UserTypes";
import { getPartnershipByID } from "@/api/partnership";
import AddressConfirmation from "./AddressConfirmation";

export default function Rates({ getRatesFn }: { getRatesFn?: Function }) {
    const { prevStep, nextStep } = useStepper();
    const [rates, setRates] = useState<RatesResponse[]>([]);
    const [loading, setLoading] = useState(true);
    const { toast } = useToast();
    const navigate = useNavigate();
    const userData = useUserStore((state) => state.userData);
    const [selectedRate, setSelectedRate] = useState("");
    const [selectedRateCarrier, setSelectedRateCarrier] = useState("");
    const shipmentDetails = useFormStore((state) => state.shipment);
    const [validationLoading, setValidationLoading] = useState(false);
    const [shippersAddressValidationResult, setShippersAddressValidationResult] = useState<Record<string, any>>({});
    const [receiversAddressValidationResult, setReceiversAddressValidationResult] = useState<Record<string, any>>({});
    const [selectedShippersAddress, setSelectedShippersAddress] = useState<Address>(shipmentDetails?.shipper?.address);
    const [selectedReceiversAddress, setSelectedReceiversAddress] = useState<Address>(shipmentDetails?.receiver?.[0]?.address);

    const handleRateSelection = async (rate: RatesResponse) => {
        setSelectedRate(rate.ServiceCode);

        const carrier = rate.carrier;
        setSelectedRateCarrier(carrier);
        // dont run the validation if it already ran for a carrier
        if (!shippersAddressValidationResult[carrier] && !receiversAddressValidationResult[carrier]) {
            setValidationLoading(true);
            try {
                // Use FedEx validation for non US UPS shipments or GLS shipments

                let shippersValidationCarrier = carrier;

                if ((carrier === "UPS" && shipmentDetails?.shipper?.address?.countryCode !== "US") || carrier === "GLS") {
                    shippersValidationCarrier = "FedEx";
                }

                const validateShippersAddressResult = await validateAddress(shipmentDetails?.shipper?.address, shippersValidationCarrier);

                if (validateShippersAddressResult) {
                    shippersAddressValidationResult[carrier] = validateShippersAddressResult;
                    setShippersAddressValidationResult(shippersAddressValidationResult);
                }

                let receiversValidationCarrier = carrier;

                if ((carrier === "UPS" && shipmentDetails?.receiver?.[0]?.address?.countryCode !== "US") || carrier === "GLS") {
                    receiversValidationCarrier = "FedEx";
                }

                const validateReceiversAddressResult = await validateAddress(shipmentDetails?.receiver?.[0]?.address, receiversValidationCarrier);

                if (validateReceiversAddressResult) {
                    receiversAddressValidationResult[carrier] = validateReceiversAddressResult;
                    setReceiversAddressValidationResult(receiversAddressValidationResult);
                }
            } catch (e) {
                console.error(e);
            }

            setValidationLoading(false);
        }
    };

    useEffect(() => {
        let errorShown = false;

        const getRates = async () => {
            // logic copied from getRates() in rates.ts and modified to handle Purolator address error preventing getting rates

            let promises: Promise<any>[] = [];

            useRatesStore.getState().removeRates();

            const carrierFunctions = {
                UPS: UPSRates,
                FedEx: FedExRates,
                GLS: GLSRates,
                Purolator: async (payload: Shipment, partnership: Partnerships | undefined, partnershipsID: string | undefined) => {
                    try {
                        await PurolatorRates(payload, partnership, partnershipsID);
                    } catch (rateError) {
                        if (rateError?.response?.data?.ResponseInformation?.Errors?.Error?.length > 0) {
                            try {
                                const shippersValidate = await validateAddress(shipmentDetails?.shipper?.address, "FedEx");

                                const payloadDeepCopy = structuredClone(payload); // deep copy to prevent overwriting the payload object

                                if (shippersValidate) {
                                    payloadDeepCopy.shipper.address = shippersValidate?.resolvedAddresses[0];
                                }

                                const receiversValidate = await validateAddress(shipmentDetails?.receiver?.[0]?.address, "FedEx");

                                if (receiversValidate) {
                                    payloadDeepCopy.receiver[0].address = receiversValidate?.resolvedAddresses[0];
                                }

                                await PurolatorRates(payloadDeepCopy, partnership, partnershipsID);

                                if (shippersValidate) {
                                    shippersAddressValidationResult["Purolator"] = shippersValidate;
                                    shippersAddressValidationResult["Purolator"].required = true;
                                    setShippersAddressValidationResult(shippersAddressValidationResult);
                                }

                                if (receiversValidate) {
                                    receiversAddressValidationResult["Purolator"] = receiversValidate;
                                    receiversAddressValidationResult["Purolator"].required = true;
                                    setReceiversAddressValidationResult(receiversAddressValidationResult);
                                }
                            } catch (e) {
                                console.error("Error retrying Purolator with FedEx address resolution", e);
                                for (const error of rateError?.response?.data?.ResponseInformation?.Errors?.Error || []) {
                                    let message = <></>;
                                    let canadaPost = (
                                        <>
                                            Try checking Canada Post for the correct address:
                                            <br />
                                            <a
                                                href="https://www.canadapost-postescanada.ca/pca/purolator?LOCALE=en"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ color: "blue", textDecoration: "underline" }}>
                                                https://www.canadapost-postescanada.ca/pca/purolator?LOCALE=en
                                            </a>
                                        </>
                                    );

                                    if (error?.Code === "1100594") {
                                        // "Receiver Postal Code is invalid."
                                        message = (
                                            <>
                                                The receiver's postal code is invalid.
                                                <br />
                                                {`${shipmentDetails.receiver[0]?.address.street}, ${shipmentDetails.receiver[0]?.address.city}, ${shipmentDetails.receiver[0]?.address.stateCode} ${shipmentDetails.receiver[0]?.address.postalCode}`}
                                                <br />
                                                {shipmentDetails.receiver[0]?.address.countryCode === "CA" && canadaPost}
                                            </>
                                        );
                                    } else if (error?.Code === "1100213") {
                                        // "Postal Code is invalid." (probably means shipper's address)
                                        message = (
                                            <>
                                                The shipper's postal code is invalid.
                                                <br />
                                                {`${shipmentDetails.shipper?.address.street}, ${shipmentDetails.shipper?.address.city}, ${shipmentDetails.shipper?.address.stateCode} ${shipmentDetails.shipper?.address.postalCode}`}
                                                <br />
                                                {shipmentDetails.shipper?.address.countryCode === "CA" && canadaPost}
                                            </>
                                        );
                                    } else {
                                        // tons of error codes possible here, fallback to just an address check toast
                                        message = (
                                            <>
                                                Error getting Purolator Rates, possible address issue.
                                                <br />
                                                {(shipmentDetails.shipper?.address.countryCode === "CA" || shipmentDetails.receiver[0]?.address.countryCode === "CA") && canadaPost}
                                            </>
                                        );
                                    }

                                    errorShown = true;

                                    toast({
                                        title: "Purolator Error",
                                        description: <>{message}</>,
                                        variant: "destructive",
                                        duration: 10000
                                    });
                                }
                            }
                        }
                    }
                }
            };

            let carrierNotActive = true;
            const activeCarriers = useUserStore.getState().userData?.activeCarriers;
            const partnershipsID = useUserStore.getState().userData?.partnerships;
            let payload: Shipment = useFormStore.getState().shipment;

            let partnership: Partnerships | undefined;
            if (partnershipsID) {
                try {
                    partnership = await getPartnershipByID(partnershipsID);
                } catch (e) {
                    console.error(e);
                }
            }

            for (const carrier in activeCarriers) {
                if (activeCarriers[carrier] && activeCarriers[carrier].enabled) {
                    const promise = carrierFunctions[carrier](payload, partnership, partnershipsID);
                    promises.push(promise);
                    carrierNotActive = false;

                    // TODO: figure out how to do this while doing reset logic
                    // promise.then(() => { // do UI updates per promise
                    //     const ratesStore = useRatesStore.getState();
                    //     if (ratesStore.rates.length > 0) {
                    //         const result = ratesStore.rates;
                    //         const filteredRates = filterRate(result);
                    //         sortRates("rates", filteredRates);
                    //     }
                    // })
                }
            }

            if (carrierNotActive) {
                toast({
                    title: "Carriers are not enabled",
                    variant: "default",
                    action: (
                        <ToastAction
                            altText="Enable Carrier"
                            onClick={() => {
                                navigate("/settings/carriers", { replace: true });
                            }}
                            className="">
                            Enable Carrier
                        </ToastAction>
                    )
                });

                return;
            }

            await Promise.allSettled(promises);
        };
        const fetchRates = async () => {
            await (getRatesFn ? getRatesFn() : getRates());
            const ratesStore = useRatesStore.getState();
            if (ratesStore.rates.length > 0) {
                const result = ratesStore.rates;
                const filteredRates = filterRate(result);
                sortRates("rates", filteredRates);
                setLoading(false);
            }
            // prevent overwriting the Purolator specific error
            // TODO: update toast component to handle multiple toasts / chaining toasts
            else if (!errorShown) {
                toast({ title: "Error fetching rates. Please try again later.", variant: "destructive" });
            }
        };

        fetchRates();

        return () => {
            setShippersAddressValidationResult({});
            setReceiversAddressValidationResult({});
        };
    }, []);

    const filterRate = (rates: RatesResponse[]) => {
        let result = rates.filter((item, index, self) => index === self.findIndex((t) => t.ServiceCode === item.ServiceCode));
        return result;
    };

    const sortRates = (method: "rates" | "transitTimes" | "carrier" | string, rates: RatesResponse[]) => {
        if (method === "rates") {
            const sortedRates = rates.sort(sortByRates);
            useRatesStore.getState().removeRates();
            sortedRates.forEach((rate) => useRatesStore.getState().addRates(rate));
            setRates(useRatesStore.getState().rates);
        }
        if (method === "transitTimes") {
            const sortedRates = rates.sort(sortByTimes);
            useRatesStore.getState().removeRates();
            sortedRates.forEach((rate) => useRatesStore.getState().addRates(rate));
            setRates(useRatesStore.getState().rates);
        }
        if (method === "carrier") {
            const sortedRates = rates.sort(sortByCarrier);
            useRatesStore.getState().removeRates();
            sortedRates.forEach((rate) => useRatesStore.getState().addRates(rate));
            setRates(useRatesStore.getState().rates);
        }
    };

    const editData = () => {
        useFormStore.getState().removeServiceDetails();
        console.log(useFormStore.getState().shipment);
        useRatesStore.getState().removeRates();
        prevStep();
    };

    const checkToDisableSubmit = () => {
        let check = selectedRate === "" || validationLoading;

        if (shippersAddressValidationResult[selectedRateCarrier]?.required) {
            check =
                check ||
                !shippersAddressValidationResult[selectedRateCarrier]?.resolvedAddresses.some((address: Address) => {
                    return (
                        address.street === selectedShippersAddress.street &&
                        address.city === selectedShippersAddress.city &&
                        address.stateCode === selectedShippersAddress.stateCode &&
                        address.postalCode === selectedShippersAddress.postalCode
                    );
                });
        }

        if (receiversAddressValidationResult[selectedRateCarrier]?.required) {
            check =
                check ||
                !receiversAddressValidationResult[selectedRateCarrier]?.resolvedAddresses.some((address: Address) => {
                    return (
                        address.street === selectedReceiversAddress.street &&
                        address.city === selectedReceiversAddress.city &&
                        address.stateCode === selectedReceiversAddress.stateCode &&
                        address.postalCode === selectedReceiversAddress.postalCode
                    );
                });
        }

        return check;
    };

    const confirmShipment = () => {
        if (useFormStore.getState().serviceDetails.serviceCode) {
            console.log(useFormStore.getState().serviceDetails);

            const shipment = useFormStore.getState().shipment;

            shipment.shipper.address = selectedShippersAddress;

            for (const receiver of shipment.receiver) {
                receiver.address = selectedReceiversAddress;
            }

            useFormStore.getState().addShipment(shipment);

            // console.log("next step");
            nextStep();
        } else {
            // console.log("select rate");
        }
    };

    const showAddressConfirmation = selectedRate && selectedRateCarrier && !validationLoading;

    // console.log(useRatesStore((state) => state.rates));

    return (
        <>
            <Card>
                <CardHeader className="flex flex-row items-center gap-2">
                    <div className="grid items-center">
                        <CardTitle className="text-base font-medium">Estimated Rates</CardTitle>
                        <CardDescription className="mr-20 text-xs">
                            These rates are estimated based on the provided information. Carriers reserve the right to resize, reweigh packages, and reassess prices upon arrival at carrier hubs. Any
                            adjustments will be reflected on your SnapShip invoice.
                        </CardDescription>
                    </div>
                    <div className="ml-auto grid w-1/4 gap-2">
                        <Label htmlFor="sortBy">Sort by: </Label>
                        <Select
                            defaultValue="rates"
                            onValueChange={(value) => {
                                sortRates(value, rates);
                            }}>
                            <SelectTrigger className="" name="sortBy" id="sortBy">
                                <SelectValue placeholder={"Select Sort Method"} />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value={"rates"}>Rates</SelectItem>
                                <SelectItem value={"carrier"}>Carrier</SelectItem>
                                <SelectItem value={"transitTimes"}>Transit Times</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </CardHeader>

                <CardContent className="grid grid-cols-3 gap-6">
                    {rates.map((rate, index: number) => (
                        <RatesCard Rates={rate} selectedRate={selectedRate} setSelectedRate={() => handleRateSelection(rate)} key={index} />
                    ))}

                    {loading && (
                        <>
                            {Array.from({ length: 3 }).map((_, index) => (
                                <Card className="border border-gray-200" key={`rates-loader-${index}`}>
                                    <CardHeader className="gap-4">
                                        <CardTitle className="flex h-3.5 items-center gap-3 text-sm">
                                            <Skeleton className="h-full w-10 object-cover" />
                                            <Skeleton className="h-4 w-3/4" />
                                        </CardTitle>
                                        <CardDescription>
                                            <Skeleton className="h-4 w-1/2" />
                                        </CardDescription>
                                    </CardHeader>
                                    <CardContent>
                                        <Skeleton className="h-10 w-full" />
                                    </CardContent>
                                </Card>
                            ))}
                        </>
                    )}
                </CardContent>
            </Card>

            {/* Skeleton loading for confirm address section */}
            {selectedRate && validationLoading && (
                <Card className="border border-gray-200 p-4">
                    <CardHeader>
                        <CardTitle className="text-base font-medium">
                            <Skeleton className="h-5 w-1/2" />
                        </CardTitle>
                        <CardDescription>
                            <Skeleton className="h-4 w-full" />
                            <Skeleton className="mt-2 h-4 w-3/4" />
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            <Card className="border p-4">
                                <label className="flex items-center gap-4">
                                    <Skeleton className="h-5 w-1/4 flex-shrink-0" />
                                    <div className="text-sm text-gray-700">
                                        <Skeleton className="h-4 w-3/4" />
                                        <Skeleton className="mt-1 h-3 w-1/2" />
                                    </div>
                                </label>
                            </Card>

                            <Card className="mt-4 p-4" style={{ backgroundColor: "#FEDF89" }}>
                                <CardTitle className="text-base font-medium">
                                    <Skeleton className="h-5 w-1/2" />
                                </CardTitle>
                                <div className="space-y-4">
                                    {Array.from({ length: 2 }).map((_, index) => (
                                        <Card key={`validation-loader-${index}`} className="mt-4 cursor-pointer border p-4 hover:shadow-sm">
                                            <label className="flex items-center gap-4">
                                                <Skeleton className="h-5 w-1/4 flex-shrink-0" />
                                                <div className="text-sm text-gray-700">
                                                    <Skeleton className="h-4 w-3/4" />
                                                    <Skeleton className="mt-1 h-3 w-1/2" />
                                                </div>
                                            </label>
                                        </Card>
                                    ))}
                                </div>
                            </Card>
                        </div>
                    </CardContent>
                </Card>
            )}

            {showAddressConfirmation && shippersAddressValidationResult[selectedRateCarrier]?.resolvedAddresses?.length > 0 && (
                <AddressConfirmation
                    type={"shipper"}
                    setSelectedAddress={setSelectedShippersAddress}
                    selectedRateCarrier={selectedRateCarrier}
                    shipmentDetailsEntity={shipmentDetails.shipper}
                    validationResult={shippersAddressValidationResult}
                />
            )}

            {showAddressConfirmation && receiversAddressValidationResult[selectedRateCarrier]?.resolvedAddresses?.length > 0 && (
                <AddressConfirmation
                    type={"receiver"}
                    setSelectedAddress={setSelectedReceiversAddress}
                    selectedRateCarrier={selectedRateCarrier}
                    shipmentDetailsEntity={shipmentDetails.receiver[0]}
                    validationResult={receiversAddressValidationResult}
                />
            )}

            <div className="flex gap-6">
                <Button type="button" onClick={editData} variant="outline" className="flex-grow">
                    Back
                </Button>
                <Button variant="default" type="submit" className="flex-grow" disabled={checkToDisableSubmit()} onClick={confirmShipment}>
                    {/* {steps[6].label !== "Customs" ? "Select Rate" : "Fill out Customs"} */}
                    {selectedRate === "" ? "Select Rate" : "Fill out Handoff"}
                </Button>
            </div>
        </>
    );
}

const sortByRates = (a: RatesResponse, b: RatesResponse) => {
    const cost1 = a.cost;
    const cost2 = b.cost;

    if (cost1 < cost2) {
        return -1;
    }

    if (cost1 > cost2) {
        return 1;
    }

    return 0;
};

const sortByTimes = (a: RatesResponse, b: RatesResponse) => {
    const time1 = a.transitTimes;
    const time2 = b.transitTimes;

    if (time1 === 0) {
        return 1;
    }

    if (time2 === 0) {
        return -1;
    }

    if (time1 < time2) {
        return -1;
    }

    if (time1 > time2) {
        return 1;
    }

    return 0;
};

const sortByCarrier = (a: RatesResponse, b: RatesResponse) => {
    const carrier1 = a.carrier;
    const carrier2 = b.carrier;

    return carrier1.localeCompare(carrier2);
};
