import { Address, Customer } from "@shared/ShipmentTypes";
import { doc, updateDoc, arrayUnion, arrayRemove, setDoc, collection, getDocs, query, where, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "@/core/firebase";
import dayjs from "dayjs";
import { Carrier } from "@shared/Carrier";
import axios from "axios";
import { ValidateAddressResponse } from "@shared/ResponseTypes";

export const addAddress = async (data: Customer, uid?: string) => {
    if (!uid) {
        throw "uid not defined";
    }
    console.log(data);
    try {
        const addressBookRef = doc(db, "addressBook", uid);
        await setDoc(addressBookRef, { address: arrayUnion(data) }, { merge: true });
        console.log("created address");
    } catch (error) {
        throw new Error(`Failed to create address: ${error.message}`);
    }
};

export const getAddresses = async (uid?: string) => {
    if (!uid) {
        throw "uid not defined";
    }

    try {
        const addressBook = await getDoc(doc(db, "addressBook", uid));

        if (addressBook.exists()) {
            // console.log("Document data:", addressBook.data());
            return addressBook.data().address as Customer[];
        } else {
            // address.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        throw new Error(`Failed to get address: ${error.message}`);
    }
};

export const getAddress = async (index: number, uid?: string) => {
    if (!uid) {
        throw "uid not defined";
    }

    try {
        const addressBook = await getDoc(doc(db, "addressBook", uid));

        if (addressBook.exists()) {
            const address = addressBook.data().address as Customer[];
            return address[index];
        } else {
            // address.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        throw new Error(`Failed to get address: ${error.message}`);
    }
};

export const removeAddress = async (address: Customer, uid?: string) => {
    if (!uid) {
        throw "uid not defined";
    }
    console.log(address);
    try {
        const addressBookRef = doc(db, "addressBook", uid);
        await setDoc(addressBookRef, { address: arrayRemove(address) }, { merge: true });
        console.log("removed address");
    } catch (error) {
        throw new Error(`Failed to create address: ${error.message}`);
    }
};

export const editAddress = async (oldAddress: Customer, newAddress: Customer, uid?: string) => {
    if (!uid) {
        throw "uid not defined";
    }

    try {
        await removeAddress(oldAddress, uid);
        await addAddress(newAddress, uid);
    } catch (error) {
        throw new Error(`Failed to edit address: ${error.message}`);
    }
};

export const validateAddress = async (address: Address, carrier: Carrier): Promise<ValidateAddressResponse> => {
    const response = await axios.post(`${import.meta.env.VITE_API_URL}/validate/address`, {
        address,
        carrier
    });
    return response.data;
};
