import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { sendSupplyEmail } from "@/api/emails/sendSupplyEmail";
import { updateUserCarriers } from "@/api/user/updateUser";
import CarrierLogo from "@/components/composite/CarrierLogo";
import FormWrapper from "@/components/composite/Form";
import Input from "@/components/composite/Inputs/Input";
import Select from "@/components/composite/Inputs/Select";
import Textbox from "@/components/composite/Inputs/Textbox";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Switch } from "@/components/ui/switch";
import { toast } from "@/components/ui/use-toast";
import { useCarrierDialog } from "@/context/CarrierDialog";
import { useUserStore } from "@/core/UserStore";
import { suppliesSchema } from "@/schema/SuppliesSchema";
import { carrierSchema } from "@/schema/UserSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import SectionHeader from "../../../components/composite/Headers/SectionHeader";
import { CircleAlert, Container, Info } from "lucide-react";
import { MultiSelect } from "@/components/ui/multi-select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Carrier } from "@shared/Carrier";
import { set } from "lodash";

const carrierList = [
    { name: "UPS", label: "UPS" },
    { name: "FedEx", label: "FedEx" },
    { name: "Purolator", label: "Purolator" },
    { name: "GLS", label: "GLS" }
];

const packagingTypeList = [
    { name: "envelopes", label: "Envelopes" },
    { name: "paks", label: "Paks" },
    { name: "carrierBoxes", label: "Carrier Boxes" },
    { name: "tubes", label: "Tubes" },
    { name: "labels", label: "Shipping Labels" },
    { name: "thermalLabels", label: "Thermal Label" },
    { name: "labelPouches", label: "Shipping Label Pouches" }
];

const carrierPackagingNotes = [
    "Envelopes (Can hold documents up to 1 lb per envelope)",
    "Paks (Can hold items up to 3 lbs per pak)",
    "Carrier Boxes (Boxes come in various sizes, please indicate which size you require in the comment section)",
    "Tubes (Primarily for architectural drawings)",
    "Shipping Labels (For use with a printer)",
    "Shipping Label Pouches (Comes with an adhesive backing for easy application)"
];

export default function CarriersForm() {
    const [currentCarrier, setCurrentCarrier] = useState<Carrier | undefined>(undefined); // used for activating and deactivating carrier rates
    const [visibility, setVisibility] = useState(false);
    const [termsRead, setTermsRead] = useState(false);
    const [selectedPackageTypes, setSelectedPackageTypes] = useState<string[]>([]);
    const { open, setOpen } = useCarrierDialog();
    const [carrier, setCarrier] = useState<Carrier | undefined>(undefined); // used for requesting carrier packaging

    const user = useUserStore((state) => state.user);
    const userData = useUserStore((state) => state.userData);
    const activeCarriers = useUserStore((state) => state.userData?.activeCarriers);

    const form = useForm<z.infer<typeof carrierSchema>>({
        resolver: zodResolver(carrierSchema),
        defaultValues: {
            UPS: activeCarriers?.UPS?.enabled,
            FedEx: activeCarriers?.FedEx?.enabled,
            Purolator: activeCarriers?.Purolator?.enabled,
            GLS: activeCarriers?.GLS?.enabled
        }
    });

    // methods for supplies
    const methods = useForm<z.infer<typeof suppliesSchema>>({
        resolver: zodResolver(suppliesSchema),
        mode: "onChange",
        defaultValues: {
            email: user?.email ?? "N/A",
            company: userData?.companyName ?? "N/A"
        }
    });

    useEffect(() => {
        if (selectedPackageTypes.length !== 0) {
            if (carrier === "GLS") {
                setSelectedPackageTypes(selectedPackageTypes.filter((packageType) => packageType === "thermalLabels" || packageType === "envelopes" || packageType === "paks"));
            }
        }
    }, [carrier]);

    useEffect(() => {
        if (userData !== undefined && activeCarriers === undefined) {
            updateUserCarriers(userData?.email, {
                UPS: {
                    enabled: false,
                    dateSigned: null
                },
                FedEx: {
                    enabled: false,
                    dateSigned: null
                },
                Purolator: {
                    enabled: false,
                    dateSigned: null
                },
                GLS: {
                    enabled: false,
                    dateSigned: null
                }
            }).then((userUpdated) => useUserStore.getState().setUserData(userUpdated));
            form.setValue("FedEx", false);
            form.setValue("UPS", false);
            form.setValue("Purolator", false);
            form.setValue("GLS", false);
        }
    }, [activeCarriers]);

    const previousValues = useRef<{
        userData: typeof userData | undefined;
        activeCarriers: typeof activeCarriers | undefined;
        form: typeof form | undefined;
    }>({
        userData: undefined,
        activeCarriers: undefined,
        form: undefined
    });
    useEffect(() => {
        // TODO: make this dynamic so that it can be used for any new carriers
        if (activeCarriers && activeCarriers.GLS === undefined) {
            // add GLS to carriers object for user
            updateUserCarriers(userData?.email, {
                ...userData?.activeCarriers,
                GLS: {
                    enabled: false,
                    dateSigned: null
                }
            }).then((userUpdated) => {
                useUserStore.getState().setUserData(userUpdated);
            });

            form.setValue("GLS", false);
        }

        if (activeCarriers && activeCarriers.GLS !== undefined) {
            form.reset({
                UPS: activeCarriers.UPS.enabled,
                FedEx: activeCarriers.FedEx.enabled,
                Purolator: activeCarriers.Purolator.enabled,
                GLS: activeCarriers.GLS.enabled
            });
        }

        // Update the previous values
        previousValues.current = { userData, activeCarriers, form };
    }, [userData]);

    useEffect(() => {
        methods.register("packageType");
    }, [methods]);

    function onSubmit(data: z.infer<typeof carrierSchema>) {
        Object.keys(form.formState.dirtyFields).forEach((carrier) => {
            if (data[carrier]) {
                toast({
                    title: "Carrier rates enabled",
                    description: `You have enabled rates for ${carrier}`,
                    className: "bg-success-400 m-px"
                });
            } else {
                toast({
                    title: "Carrier rates disabled",
                    description: `You have disabled rates for ${carrier}`,
                    variant: "default"
                });
            }
            if (activeCarriers) {
                activeCarriers[carrier] = {
                    ...activeCarriers[carrier],
                    enabled: data[carrier],
                    dateSigned: data[carrier] ? new Date().toISOString() : null
                };
            }
        });

        if (activeCarriers) {
            updateUserCarriers(user?.email, activeCarriers).then((userUpdated) => useUserStore.getState().setUserData(userUpdated));
        }

        setVisibility(false);
        form.reset({
            UPS: activeCarriers?.UPS.enabled,
            FedEx: activeCarriers?.FedEx.enabled,
            Purolator: activeCarriers?.Purolator.enabled,
            GLS: activeCarriers?.GLS.enabled
        });
    }

    const handleSwitchChange = (newValue: boolean, oldValue: boolean, name: Carrier, fieldOnChange: Function) => {
        if (!oldValue && newValue) {
            setCurrentCarrier(name);
            setTermsRead(false);
            setVisibility(true);
        } else if (!newValue) {
            form.setValue(name, newValue);
            form.handleSubmit(onSubmit)();
        }
        fieldOnChange(newValue);
    };

    const handleScroll = (event: any) => {
        const element = event.target;
        if (Math.ceil(element.scrollTop) + element.clientHeight >= element.scrollHeight) {
            setTermsRead(true);
        }
    };

    async function onSuppliesSubmit(data: z.infer<typeof suppliesSchema>) {
        setOpen(false);
        methods.reset();
        try {
            await sendSupplyEmail(data);
            toast({
                title: "Supplies Requested!",
                description: "You can expect your supplies to arrive within 2-4 business days. A SnapShip associate may contact you if more information is needed.",
                variant: "success"
            });
        } catch (e) {
            toast({ title: "Unable to request supplies!", description: `${e.message}. Please try again later!`, variant: "destructive" });
        }
        setSelectedPackageTypes([]);
    }

    return (
        <div className="flex flex-col">
            <SectionHeader title="Carriers" description="Enable or disable carrier rates here." />
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6" id="first-step">
                    <div className="mb-6 space-y-4">
                        <FormField
                            control={form.control}
                            name="UPS"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                                    <div className="flex-grow space-y-0.5">
                                        <FormLabel className="flex items-center">
                                            {CarrierLogo({ carrier: "UPS", className: " h-5 mr-2" })}
                                            UPS Rates
                                        </FormLabel>
                                        <FormDescription>Receive rates for UPS.</FormDescription>
                                    </div>
                                    <FormControl>
                                        <Switch checked={field.value} onCheckedChange={(newValue) => handleSwitchChange(newValue, field.value, field.name, field.onChange)} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="FedEx"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                                    <div className="flex-grow space-y-0.5">
                                        <FormLabel className="flex items-center">
                                            {CarrierLogo({ carrier: "FedEx", className: " h-5 w-10 mr-2" })}
                                            FedEx Rates
                                        </FormLabel>
                                        <FormDescription>Receive rates for FedEx.</FormDescription>
                                    </div>
                                    <FormControl>
                                        <Switch checked={field.value} onCheckedChange={(newValue) => handleSwitchChange(newValue, field.value, field.name, field.onChange)} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="Purolator"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                                    <div className="flex-grow space-y-0.5">
                                        <FormLabel className="flex items-center">
                                            {CarrierLogo({ carrier: "Purolator", className: "h-5 w-16 mr-2" })}
                                            Purolator Rates
                                        </FormLabel>
                                        <FormDescription>Receive rates for Purolator.</FormDescription>
                                    </div>
                                    <FormControl>
                                        <Switch checked={field.value} onCheckedChange={(newValue) => handleSwitchChange(newValue, field.value, field.name, field.onChange)} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="GLS"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                                    <div className="flex-grow space-y-0.5">
                                        <FormLabel className="flex items-center">
                                            {CarrierLogo({ carrier: "GLS", className: "w-8 mr-2" })}
                                            GLS Rates
                                        </FormLabel>
                                        <FormDescription>Receive rates for GLS.</FormDescription>
                                    </div>
                                    <FormControl>
                                        <Switch checked={field.value} onCheckedChange={(newValue) => handleSwitchChange(newValue, field.value, field.name, field.onChange)} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>

                    <Dialog
                        open={visibility}
                        onOpenChange={(isOpen) => {
                            setVisibility(isOpen);
                            form.reset({
                                UPS: activeCarriers?.UPS.enabled,
                                FedEx: activeCarriers?.FedEx.enabled,
                                Purolator: activeCarriers?.Purolator.enabled
                            });
                        }}>
                        <DialogContent className="sm:max-h-[600px]" onClick={(e) => e.stopPropagation()}>
                            <DialogTitle>Terms of Carrier</DialogTitle>
                            <ol>
                                <DialogDescription className="max-h-[425px] space-y-4 overflow-auto overscroll-y-auto pr-4" onScroll={handleScroll}>
                                    <li>
                                        <b>Shipment Handling:</b> All shipments will be exclusively processed through {currentCarrier}. Handwritten waybills are no longer accepted. Pick-ups can be
                                        scheduled online or dropped off at the respective carrier's location. Call-in pick-ups are not available, and a call-in charge will be applied.
                                    </li>
                                    <li>
                                        <b>Waybill and Shipping Label Preparation:</b> When preparing a waybill, the full published tariff rate, along with our offered discount, will be visible. It's
                                        important to note that additional charges, such as residential surcharges, extended area surcharges, fuel surcharges, address correction fees, volumetric weight
                                        adjustments, or duties and taxes, may not be accurately reflected in the initial discount. These charges may be billed separately at a later date. The decision
                                        of the activated carrier on additional charges is final and cannot be disputed. In the event of service failure, no refunds are applicable for late delivery,
                                        late pickup, or transit delays. Shipping charge refunds are only considered in cases of total loss.
                                    </li>
                                    <li>
                                        <b>Damaged Goods:</b> For goods damaged during transit, a claim can be filed, and we will refund what the activated carrier refunds us on your behalf. It is
                                        your responsibility to purchase insurance and appropriately package the goods for transport. All terms and conditions of the activated carrier will apply and
                                        cannot be disputed with us.
                                    </li>
                                    <li>
                                        <b>Shipment Tracking:</b> It is crucial to track your shipments post-shipping. Notify us immediately of any delays or documentation requirements for timely
                                        intervention. Undeliverable shipments must be reported within 5 business days for Express and 7 days for Ground shipments due to address issues, customs
                                        problems, missing documentation, or contact information.
                                    </li>
                                    <li>
                                        <b>Discounts and Additional Charges:</b> Discounts offered are against the base rate only; value-added services are not discountable. Examples include remote
                                        location delivery surcharge, insurance/declared value charge, address correction charge, duties/taxes/brokerage and bond fees, Saturday delivery or pick-up
                                        charge, peak season surcharge, odd packaging surcharge, cling wrap charge, and oversize box surcharge, among others. During peak seasons and COVID times,
                                        carriers impose surcharges of $1.50 to $4.00 or more per shipment, based on weight or declared value. Carriers do not guarantee delivery times, and no cashback
                                        is offered for transit delays.
                                    </li>
                                    <li>
                                        <b>Import Inquiries:</b> For import-related inquiries, reach out to SnapShip operations staff for assistance. Ensure you have your CARM for duty/tax purposes
                                        and have set up accounts for free trade agreements such as CUSMA, USMCA, TMEX, or any other relevant international trade agreements.
                                    </li>
                                    <li>
                                        <b>Fuel Surcharge:</b> A fuel surcharge is additional with all carriers and is visible at the time of shipping, changing every month. Contact Information: For
                                        inquiries, supply orders, assistance, or claims, always contact us first, as your account is managed through us, allowing our staff to access information
                                        efficiently through our inside sales representatives.
                                    </li>
                                    <li>
                                        <b>Further Assistance:</b> If you require additional assistance, please reach out to our operations staff.
                                    </li>
                                </DialogDescription>
                            </ol>

                            <DialogFooter>
                                {!termsRead ? (
                                    <HoverCard>
                                        <HoverCardTrigger>
                                            <Button disabled={!termsRead} type="submit" onClick={form.handleSubmit(onSubmit)}>
                                                Accept and update
                                            </Button>
                                        </HoverCardTrigger>
                                        <HoverCardContent>Please scroll to the bottom to read the terms and conditions.</HoverCardContent>
                                    </HoverCard>
                                ) : (
                                    <Button type="submit" onClick={form.handleSubmit(onSubmit)}>
                                        Accept and update
                                    </Button>
                                )}
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                </form>
            </Form>

            <div>
                <Button
                    onClick={() => {
                        setOpen(true);
                    }}
                    id="second-step">
                    <Container className="mr-2 h-4 w-4" /> Order carrier packaging supplies
                </Button>
            </div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Supplies request</DialogTitle>
                        <DialogDescription>If you need carrier supplies for packaging your shipments, please fill out the form below.</DialogDescription>
                    </DialogHeader>
                    <FormWrapper methods={methods} onSubmit={onSuppliesSubmit} className="grid w-full gap-2 ">
                        <div className="grid max-h-[300px] gap-4 overflow-y-auto px-1">
                            <input type="hidden" {...methods.register("email")} />
                            <input type="hidden" {...methods.register("company")} />
                            <div>
                                <Select
                                    label="Carrier"
                                    SelectItemList={carrierList}
                                    name="carrier"
                                    placeholder="Select a carrier"
                                    onChange={(value) => {
                                        setCarrier(value);
                                    }}
                                />
                                {carrier === "GLS" && (
                                    <FormDescription className="flex items-center">
                                        GLS requires you to use your own boxes for shipments. If needed, you can order Purolator boxes (18" x 12" x 3.5") and use them for GLS shipments, but we
                                        recommend using your own boxes
                                    </FormDescription>
                                )}
                            </div>
                            <FormItem className="">
                                <FormLabel className="flex">
                                    <div>Package Type(s)</div>
                                    <TooltipProvider delayDuration={50}>
                                        <Tooltip>
                                            <TooltipTrigger type="button">
                                                <Info className=" h-4" />
                                            </TooltipTrigger>
                                            <TooltipContent className="text-left">
                                                <ul className="space-y-2">
                                                    {carrierPackagingNotes.map((note) => (
                                                        <li>- {note}</li>
                                                    ))}
                                                </ul>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </FormLabel>
                                <FormControl {...methods}>
                                    <MultiSelect
                                        options={
                                            carrier !== "GLS"
                                                ? packagingTypeList.map((item) => ({ ...item, value: item.name }))
                                                : [packagingTypeList[0], packagingTypeList[5], packagingTypeList[1]].map((item) => ({ ...item, value: item.name }))
                                        }
                                        onValueChange={(values) => {
                                            const [first, ...rest] = values;
                                            setSelectedPackageTypes([first, ...rest]);
                                            methods.setValue("packageType", [first, ...rest]);
                                        }}
                                        defaultValue={selectedPackageTypes}
                                        placeholder="Select package types"
                                        name="packageType"
                                    />
                                </FormControl>
                            </FormItem>
                            {selectedPackageTypes[0] !== undefined &&
                                selectedPackageTypes.map((selectedPackageType, index) => {
                                    const foundItem = packagingTypeList.find((value: { name; label }) => {
                                        return selectedPackageType === value.name;
                                    });
                                    return <Input key={selectedPackageType} label={"Amount of " + foundItem?.label} type="number" name={`amount[${index}]`} placeholder="Enter an amount" min={1} />;
                                })}

                            <Textbox name="text" label={"Additional comments (optional)"} className="resize-none" />
                        </div>
                        <DialogFooter>
                            <Button type="submit">Submit request</Button>
                        </DialogFooter>
                    </FormWrapper>
                </DialogContent>
            </Dialog>
        </div>
    );
}
