import { auth } from "@/core/firebase";

export const getToken = async () => {
    try {
        const token = await auth.currentUser?.getIdToken();
        return token
    } catch (error) {
        console.error("Error getting ID token:", error)
        return null
    }
    
}