import { Customer } from "@shared/ShipmentTypes";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { useUserStore } from "./UserStore";
import { addAddress, removeAddress } from "@/api/addresses";

interface AddressBookStore {
    addressBook: Customer[] | null;
    getAddress: (index: number) => Customer | null;
    addAddressBook: (data: Customer[]) => void;
    addAddress: (data: Customer) => void;
    removeAddress: (data: Customer) => void;
}

export const useAddressBookStore = create<AddressBookStore>()(
    persist(
        (set, get) => ({
            addressBook: null,
            getAddress: (index) => {
                const addressBook = get().addressBook;
                if (addressBook) return addressBook[index];
                else return null;
            },
            addAddressBook: (data) => {
                set({ addressBook: data });
            },
            addAddress: (data) => {
                const addressBook = get().addressBook;
                if (addressBook !== null) {
                    set({ addressBook: [...addressBook, data] });
                } else {
                    set({ addressBook: [data] });
                }

                const email = useUserStore.getState().userData?.email;
                if (email) {
                    addAddress(data, email);
                }
            },
            removeAddress: (data: Customer) => {
                const addressBook = get().addressBook;
                if (addressBook !== null) {
                    const index = addressBook.indexOf(data);
                    if (index > -1) {
                        // only splice array when item is found
                        addressBook.splice(index, 1); // 2nd parameter means remove one item only
                    }
                    set({ addressBook: addressBook });

                    const email = useUserStore.getState().userData?.email;
                    if (email) {
                        removeAddress(data, email);
                    }
                }
            }
        }),
        {
            name: "addressBook-store",
            storage: createJSONStorage(() => localStorage)
        }
    )
);
